<template>
  <div class="font-Rajdhani font-medium tracking-wide">
    <div class="fixed h-full w-full z-0">
      <img :src="ImgStar" :style="bgImg" />
    </div>

    <div class="fixed h-full w-full z-0 bgGd bg-cover"></div>
    <div
      class="fixed w-full h-full z-0 kk opacity-10"
      :style="{ 'background-image': `url(${ImgDecor})` }"
    ></div>

    <div class="relative h-full z-10">
      <router-view />
    </div>

    <base-notification />
  </div>
</template>

<script>
import ImgStar from "@/assets/images/bg-stars.jpg";
import ImgDecor from "@/assets/images/bg-decor.png";
import BaseNotification from "./components/BaseNotification";

export default {
  components: { BaseNotification },
  name: "RootApp",

  data() {
    return {
      ImgStar,
      ImgDecor,
      bgImg: {
        backgroundSize: "cover",
        height: "100%",
        width: "100%",
      },
    };
  },
};
</script>
