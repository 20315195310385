<template>
  <div class="w-full h-full top-0 left-0 fixed z-30">
    <div
      @click="TOGGLE_PROMPT(false)"
      class="bg-w-blue-2 fixed w-full h-full inset-0 z-10"
      :class="prompt ? 'block' : 'hidden'"
    >
      <div
        class="max-w-lg mx-auto min-h-screen flex flex-wrap flex-1 justify-center content-center"
      >
        <div class="prompt">
          <div class="prompt_content" data-augmented-ui="tl-clip br-clip">
            <div
              class="prompt_title text-black font-Orbitron font-semibold tracking-widest"
              data-augmented-ui="tl-clip br-clip inlay"
            >
              {{ prompt.prompt }}
            </div>
            <div
              class="prompt_description font-medium pl-5 pr-3 pt-4 pb-5"
              v-html="prompt.description"
            ></div>

            <div class="relative flex space-x-2 justify-end pr-1.5 pb-1.2">
              <!-- <button
                class="group button-cyber btn-cyber h-8 w-12 hover:bg-w-white-1 text-sm"
                variant="primary"
              >
                <span
                  class="absolute top-1.2 left-3 font-semibold text-w-white-2 group-hover:text-w-cyber-blue-1"
                  >NO_</span
                >
              </button> -->
              <slot></slot>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  name: "BasePrompt",
  computed: {
    ...mapState({
      prompt: (state) => state.menu.prompt,
    }),
  },
  methods: {
    ...mapActions("menu", ["TOGGLE_PROMPT"]),
  },
};
</script>
