<template>
  <div class="flex items-center">
    <h6 class="text-w-white-4 text-sm hidden sm:flex">
      Showing&nbsp;
      {{ (current - 1) * pagination.per_page + 10 + 1 - 10 }}
      &nbsp;to &nbsp;
      <span v-if="(current - 1) * pagination.per_page + 10 > pagination.total">
        {{ pagination.total }}
      </span>
      <span v-else>{{ (current - 1) * pagination.per_page + 10 }} </span>
      &nbsp; of &nbsp;{{ pagination.total }}&nbsp; entries
    </h6>

    <h6 class="flex sm:hidden text-w-white-4 text-sm">
      {{ (current - 1) * pagination.per_page + 10 + 1 - 10 }}&nbsp;/&nbsp;
      <span v-if="(current - 1) * pagination.per_page + 10 > pagination.total">
        {{ pagination.total }}
      </span>
      <span v-else>{{ (current - 1) * pagination.per_page + 10 }} </span>
    </h6>
  </div>
</template>

<script>
export default {
  name: "ShowingEntries",
  props: ["pagination", "current"],
};
</script>
