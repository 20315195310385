export const INSTRUMENT_INDEX = (state, data) => {
  state.instrument.datatables = data;
};

export const BRAND_LISTS = (state, data) => {
  data.forEach((d) => {
    d.label = d.name;
  });
  state.brand.lists = data;
};

export const BRAND_INDEX = (state, data) => {
  state.brand.datatables = data;
};

export const SEGMENT_INDEX = (state, data) => {
  state.segment.datatables = data;
};
