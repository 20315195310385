export const CUSTOMER_INDEX = (state, data) => {
  state.customers = data;
};

export const CUSTOMER_FORM = (state, data) => {
  state.form = data;
};

export const CUSTOMER_SHOW = (state, data) => {
  state.customer = data;
};

export const CUSTOMER_GROUP_LIST = (state, data) => {
  state.listGroups = data;
};

export const CUSTOMER_GROUP_INDEX = (state, data) => {
  state.groups = data;
};

export const CUSTOMER_TYPE_LIST = (state, data) => {
  state.listTypes = data;
};

export const CUSTOMER_TYPE_INDEX = (state, data) => {
  state.types = data;
};
