import Vue from "vue";
import VueRouter from "vue-router";
import routes from "./routes";
import store from "../store/index";

Vue.use(VueRouter);

Vue.router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
  routes,
});

const waitForStorageToBeReady = async (to, from, next) => {
  await store.restored;
  // check auth
  if (to.matched.some((record) => record.meta.auth)) {
    // get status from store
    if (store.state.auth.loggedIn == false) {
      next("/login");
    } else {
      next();
    }
  } else {
    next();
  }
};

Vue.router.beforeEach(waitForStorageToBeReady);

export default Vue.router;
