var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"customers-add space-y-4"},[_c('div',{staticClass:"flex justify-between items-center pb-1"},[_c('div',{staticClass:"text-white font-semibold text-xl sm:text-3xl"},[_vm._v(" Create "+_vm._s(_vm.$route.meta.title)+" ")]),_c('button',{staticClass:"cyber-btn-primary px-3.5 py-1.5 font-medium text-w-cyber-blue-1 hover:bg-w-cyber-blue-2 duration-100",attrs:{"data-augmented-ui":"br-clip"},on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v(" Back_ ")])]),_c('div',{staticClass:"grid sm:grid-cols-2 gap-3"},[_c('line-thin',[_c('div',{staticClass:"z-5 relative p-4"},[_c('div',{staticClass:"grid gap-3"},[_c('div',{staticClass:"w-full"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"form-label bg-w-white-2 text-w-blue-2",class:{
                  'bg-w-cyber-red-1 text-w-cyber-red-2':
                    _vm.v$.form.number.$error,
                }},[_vm._v(" Number ")]),_c('div',{staticClass:"form-control",class:_vm.v$.form.number.$error
                    ? 'border-w-cyber-red-1 border before:border-b-2 before:border-w-cyber-red-1'
                    : 'border-w-white-2 border before:border-b-2 before:border-w-white-2'},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.number),expression:"form.number"}],staticClass:"form-control px-3 py-2 text-white text-sm placeholder-w-white-2",class:{
                    'text-w-cyber-red-1': _vm.v$.form.number.$error,
                  },attrs:{"placeholder":"Customer Number"},domProps:{"value":(_vm.form.number)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "number", $event.target.value)}}})])]),(_vm.v$.form.number.$error)?_c('div',{staticClass:"text-red-500 font-semibold text-xs capitalize mt-1"},[_vm._v(" "+_vm._s(`${_vm.v$.form.number.$errors[0].$property} ${_vm.v$.form.number.$errors[0].$message}`)+" ")]):_vm._e()]),_c('div',{staticClass:"w-full"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"form-label bg-w-white-2 text-w-blue-2",class:{
                  'bg-w-cyber-red-1 text-w-cyber-red-2': _vm.v$.form.name.$error,
                }},[_vm._v(" Name ")]),_c('div',{staticClass:"form-control",class:_vm.v$.form.name.$error
                    ? 'border-w-cyber-red-1 border before:border-b-2 before:border-w-cyber-red-1'
                    : 'border-w-white-2 border before:border-b-2 before:border-w-white-2'},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.name),expression:"form.name"}],staticClass:"form-control px-3 py-2 text-white text-sm placeholder-w-white-2",class:{
                    'text-w-cyber-red-1': _vm.v$.form.name.$error,
                  },attrs:{"placeholder":"Customer Name"},domProps:{"value":(_vm.form.name)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "name", $event.target.value)}}})])]),(_vm.v$.form.name.$error)?_c('div',{staticClass:"text-red-500 font-semibold text-xs capitalize mt-1"},[_vm._v(" "+_vm._s(`${_vm.v$.form.name.$errors[0].$property} ${_vm.v$.form.name.$errors[0].$message}`)+" ")]):_vm._e()]),_c('div',{staticClass:"w-full"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"form-label bg-w-white-2 text-w-blue-2",class:{
                  'bg-w-cyber-red-1 text-w-cyber-red-2':
                    _vm.v$.form.phone_number.$error,
                }},[_vm._v(" Phone Number ")]),_c('div',{staticClass:"form-control",class:_vm.v$.form.phone_number.$error
                    ? 'border-w-cyber-red-1 border before:border-b-2 before:border-w-cyber-red-1'
                    : 'border-w-white-2 border before:border-b-2 before:border-w-white-2'},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.phone_number),expression:"form.phone_number"}],staticClass:"form-control px-3 py-2 text-white text-sm placeholder-w-white-2",class:{
                    'text-w-cyber-red-1': _vm.v$.form.phone_number.$error,
                  },attrs:{"placeholder":"Customer Phone Number"},domProps:{"value":(_vm.form.phone_number)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "phone_number", $event.target.value)}}})])]),(_vm.v$.form.phone_number.$error)?_c('div',{staticClass:"text-red-500 font-semibold text-xs capitalize mt-1"},[_vm._v(" "+_vm._s(`Phone Number ${_vm.v$.form.phone_number.$errors[0].$message}`)+" ")]):_vm._e()])])])]),_c('line-thin',[_c('div',{staticClass:"relative border-b border-w-white-2"},[_c('div',{staticClass:"bg-white absolute top-0 left-0 w-full h-full opacity-10"}),_c('div',{staticClass:"text-white font-semibold z-10 relative px-4 py-2"},[_vm._v(" Area ")])]),_c('div',{staticClass:"z-5 relative space-y-4 p-4"},[_c('div',{staticClass:"grid gap-3"},[_c('div',{staticClass:"w-full"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"form-label bg-w-white-2 text-w-blue-2",class:{
                  'bg-w-cyber-red-1 text-w-cyber-red-2':
                    _vm.v$.form.area_support_id.$error,
                }},[_vm._v(" Area Support ")]),_c('div',{staticClass:"form-control h-9.5 absolute fc",class:_vm.v$.form.area_support_id.$error
                    ? 'border-w-cyber-red-1 border before:border-b-2 before:border-w-cyber-red-1'
                    : 'border-w-white-2 border before:border-b-2 before:border-w-white-2'}),_c('treeselect',{staticClass:"relative z-5 text-white text-sm placeholder-w-white-2 mt-0.5",class:{
                  'text-w-cyber-red-1': _vm.v$.form.area_support_id.$error,
                },attrs:{"multiple":false,"appendToBody":true,"placeholder":"Select Area Support","options":_vm.listSupports},model:{value:(_vm.form.area_support_id),callback:function ($$v) {_vm.$set(_vm.form, "area_support_id", $$v)},expression:"form.area_support_id"}})],1),(_vm.v$.form.area_support_id.$error)?_c('div',{staticClass:"text-red-500 font-semibold text-xs capitalize"},[_vm._v(" "+_vm._s(`Area Support ${_vm.v$.form.area_support_id.$errors[0].$message}`)+" ")]):_vm._e()]),_c('div',{staticClass:"w-full"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"form-label bg-w-white-2 text-w-blue-2",class:{
                  'bg-w-cyber-red-1 text-w-cyber-red-2':
                    _vm.v$.form.area_sales_id.$error,
                }},[_vm._v(" Area Sales ")]),_c('div',{staticClass:"form-control h-9.5 absolute fc",class:_vm.v$.form.area_sales_id.$error
                    ? 'border-w-cyber-red-1 border before:border-b-2 before:border-w-cyber-red-1'
                    : 'border-w-white-2 border before:border-b-2 before:border-w-white-2'}),_c('treeselect',{staticClass:"relative z-4 text-white text-sm placeholder-w-white-2 mt-0.5",class:{
                  'text-w-cyber-red-1': _vm.v$.form.area_sales_id.$error,
                },attrs:{"multiple":false,"options":_vm.sales,"appendToBody":true,"placeholder":"Select Area Sales"},model:{value:(_vm.form.area_sales_id),callback:function ($$v) {_vm.$set(_vm.form, "area_sales_id", $$v)},expression:"form.area_sales_id"}})],1),(_vm.v$.form.area_sales_id.$error)?_c('div',{staticClass:"text-red-500 font-semibold text-xs capitalize"},[_vm._v(" "+_vm._s(`Area Support ${_vm.v$.form.area_sales_id.$errors[0].$message}`)+" ")]):_vm._e()]),_c('div',{staticClass:"w-full"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"form-label bg-w-white-2 text-w-blue-2",class:{
                  'bg-w-cyber-red-1 text-w-cyber-red-2':
                    _vm.v$.form.area_location_id.$error,
                }},[_vm._v(" Location ")]),_c('div',{staticClass:"form-control h-9.5 absolute fc",class:_vm.v$.form.area_location_id.$error
                    ? 'border-w-cyber-red-1 border before:border-b-2 before:border-w-cyber-red-1'
                    : 'border-w-white-2 border before:border-b-2 before:border-w-white-2'}),_c('treeselect',{staticClass:"relative z-3 text-white text-sm placeholder-w-white-2 mt-0.5",class:{
                  'text-w-cyber-red-1': _vm.v$.form.area_location_id.$error,
                },attrs:{"multiple":false,"appendToBody":true,"placeholder":"Select Location","options":_vm.locations},model:{value:(_vm.form.area_location_id),callback:function ($$v) {_vm.$set(_vm.form, "area_location_id", $$v)},expression:"form.area_location_id"}})],1),(_vm.v$.form.area_location_id.$error)?_c('div',{staticClass:"text-red-500 font-semibold text-xs capitalize"},[_vm._v(" "+_vm._s(`Location ${_vm.v$.form.area_location_id.$errors[0].$message}`)+" ")]):_vm._e()])])])])],1),_c('div',{staticClass:"grid sm:grid-cols-3 gap-3"},[_c('line-thin',{staticClass:"sm:col-span-1"},[_c('div',{staticClass:"z-5 relative space-y-4 p-4"},[_c('div',{staticClass:"grid gap-3"},[_c('div',{staticClass:"w-full"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"form-label bg-w-white-2 text-w-blue-2",class:{
                  'bg-w-cyber-red-1 text-w-cyber-red-2':
                    _vm.v$.form.customer_group_id.$error,
                }},[_vm._v(" Customer Group ")]),_c('div',{staticClass:"form-control h-9.5 absolute fc",class:_vm.v$.form.customer_group_id.$error
                    ? 'border-w-cyber-red-1 border before:border-b-2 before:border-w-cyber-red-1'
                    : 'border-w-white-2 border before:border-b-2 before:border-w-white-2'}),_c('treeselect',{staticClass:"relative z-5 text-white text-sm placeholder-w-white-2 mt-0.5",class:{
                  'text-w-cyber-red-1': _vm.v$.form.customer_group_id.$error,
                },attrs:{"multiple":false,"appendToBody":true,"placeholder":"Select Customer Group","options":_vm.listGroups},model:{value:(_vm.form.customer_group_id),callback:function ($$v) {_vm.$set(_vm.form, "customer_group_id", $$v)},expression:"form.customer_group_id"}})],1),(_vm.v$.form.customer_group_id.$error)?_c('div',{staticClass:"text-red-500 font-semibold text-xs capitalize"},[_vm._v(" "+_vm._s(`Area Support ${_vm.v$.form.customer_group_id.$errors[0].$message}`)+" ")]):_vm._e()]),_c('div',{staticClass:"w-full"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"form-label bg-w-white-2 text-w-blue-2",class:{
                  'bg-w-cyber-red-1 text-w-cyber-red-2':
                    _vm.v$.form.customer_type_id.$error,
                }},[_vm._v(" Customer Type ")]),_c('div',{staticClass:"form-control h-9.5 absolute fc",class:_vm.v$.form.customer_type_id.$error
                    ? 'border-w-cyber-red-1 border before:border-b-2 before:border-w-cyber-red-1'
                    : 'border-w-white-2 border before:border-b-2 before:border-w-white-2'}),_c('treeselect',{staticClass:"relative z-4 text-white text-sm placeholder-w-white-2 mt-0.5",class:{
                  'text-w-cyber-red-1': _vm.v$.form.customer_type_id.$error,
                },attrs:{"multiple":false,"options":_vm.listTypes,"appendToBody":true,"placeholder":"Select Customer Type"},model:{value:(_vm.form.customer_type_id),callback:function ($$v) {_vm.$set(_vm.form, "customer_type_id", $$v)},expression:"form.customer_type_id"}})],1),(_vm.v$.form.customer_type_id.$error)?_c('div',{staticClass:"text-red-500 font-semibold text-xs capitalize"},[_vm._v(" "+_vm._s(`Area Support ${_vm.v$.form.customer_type_id.$errors[0].$message}`)+" ")]):_vm._e()])])])]),_c('line-thin',{staticClass:"sm:col-span-2"},[_c('div',{staticClass:"gap-4 p-4 grid sm:grid-cols-7 relative z-1"},[_c('customer-map',{staticClass:"rounded invert sm:col-span-3",attrs:{"customer":_vm.form},on:{"update:customer":function($event){_vm.form=$event}},model:{value:(_vm.location),callback:function ($$v) {_vm.location=$$v},expression:"location"}}),_c('div',{staticClass:"space-y-1 sm:col-span-4"},[_c('div',{staticClass:"w-full"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"form-label bg-w-white-2 text-w-blue-2",class:{
                  'bg-w-cyber-red-1 text-w-cyber-red-2':
                    _vm.v$.location.address.$error,
                }},[_vm._v(" Address ")]),_c('div',{staticClass:"form-control",class:_vm.v$.location.address.$error
                    ? 'border-w-cyber-red-1 border before:border-b-2 before:border-w-cyber-red-1'
                    : 'border-w-white-2 border before:border-b-2 before:border-w-white-2'},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.location.address),expression:"location.address"}],staticClass:"form-control px-3 py-2 text-white text-sm placeholder-w-white-2",class:{
                    'text-w-cyber-red-1': _vm.v$.location.address.$error,
                  },attrs:{"placeholder":"Customer Address","rows":"5"},domProps:{"value":(_vm.location.address)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.location, "address", $event.target.value)}}})])])]),(_vm.v$.location.position.lat.$error)?_c('div',{staticClass:"text-red-500 font-semibold text-xs capitalize"},[_vm._v(" Latitude and longitude is required. please select on map ")]):_vm._e(),(_vm.v$.location.address.$error)?_c('div',{staticClass:"text-red-500 font-semibold text-xs capitalize"},[_vm._v(" "+_vm._s(`Address ${_vm.v$.location.address.$errors[0].$message}`)+" ")]):_vm._e()])],1)])],1),_c('div',{staticClass:"flex justify-end pb-6 pt-1"},[_c('button',{staticClass:"cybr-btn group pr-3 pl-4 py-1 tracking-widest z-1",attrs:{"type":"submit","disabled":_vm.conn},on:{"click":_vm.toSave}},[_vm._m(0),_c('span',{staticClass:"cybr-btn__glitch font-Orbitron",attrs:{"aria-hidden":""}},[_vm._v("Create_")]),_c('span',{staticClass:"cybr-btn__tag font-Orbitron",attrs:{"aria-hidden":""}},[_vm._v("R25")])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"font-Orbitron"},[_vm._v(" Create"),_c('span',{attrs:{"aria-hidden":""}},[_vm._v("_")])])
}]

export { render, staticRenderFns }