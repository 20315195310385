<template>
  <div class="customers-add space-y-4">
    <div class="flex justify-between items-center pb-1">
      <div class="text-white font-semibold text-xl sm:text-3xl">
        Create {{ $route.meta.title }}
      </div>

      <button
        class="cyber-btn-primary px-3.5 py-1.5 font-medium text-w-cyber-blue-1 hover:bg-w-cyber-blue-2 duration-100"
        data-augmented-ui="br-clip"
        @click="$router.go(-1)"
      >
        Back_
      </button>
    </div>
    <div class="grid gap-3">
      <line-thin>
        <div class="z-5 relative p-4">
          <div class="grid pb-2">
            <div class="w-full">
              <div class="form-group">
                <label
                  class="form-label bg-w-white-2 text-w-blue-2"
                  :class="{
                    'bg-w-cyber-red-1 text-w-cyber-red-2': v$.form.name.$error,
                  }"
                >
                  Name
                </label>
                <div
                  class="form-control"
                  :class="
                    v$.form.name.$error
                      ? 'border-w-cyber-red-1 border before:border-b-2 before:border-w-cyber-red-1'
                      : 'border-w-white-2 border before:border-b-2 before:border-w-white-2'
                  "
                >
                  <input
                    class="form-control px-3 py-2 text-white text-sm placeholder-w-white-2"
                    placeholder="Area Name"
                    v-model="form.name"
                    :class="{
                      'text-w-cyber-red-1': v$.form.name.$error,
                    }"
                  />
                </div>
              </div>
              <div
                v-if="v$.form.name.$error"
                class="text-red-500 font-semibold text-xs capitalize mt-1"
              >
                {{
                  `${v$.form.name.$errors[0].$property} ${v$.form.name.$errors[0].$message}`
                }}
              </div>
            </div>
          </div>
        </div>
      </line-thin>
    </div>

    <div class="flex justify-end pb-6 pt-2">
      <button
        class="cybr-btn group pr-3 pl-4 py-1 tracking-widest z-1"
        type="submit"
        @click="toSave"
        :disabled="conn"
      >
        <span class="font-Orbitron"> Create<span aria-hidden>_</span> </span>
        <span aria-hidden class="cybr-btn__glitch font-Orbitron">Create_</span>
        <span aria-hidden class="cybr-btn__tag font-Orbitron">R25</span>
      </button>
    </div>
  </div>
</template>

<script>
import LineThin from "@/components/LineThin";
import { mapActions } from "vuex";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";

export default {
  name: "AreaSupportCreate",
  components: {
    LineThin,
  },
  data() {
    return {
      form: {
        name: "",
      },
      conn: false,
    };
  },
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    return {
      form: {
        name: {
          required,
        },
      },
    };
  },
  methods: {
    ...mapActions("areas", ["AREA_SUPPORT_CREATE"]),

    async toSave() {
      this.conn = true;
      const result = await this.v$.$validate();

      if (!result) {
        this.conn = false;
        return;
      }

      let res = await this.AREA_SUPPORT_CREATE(this.form);

      if (res) {
        this.$router.go(-1);
      }

      this.conn = false;
    },
  },
};
</script>
