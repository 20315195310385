<template>
  <div class="BaseLayout">
    <base-navbar :ww="ww" />
    <div class="flex overflow-hidden pt-14">
      <base-sidebar v-if="sidebar" :ww="ww" />
      <div
        @click="hideSide"
        class="bg-gray-900 opacity-60 fixed inset-0 z-10"
        :class="backdrop ? 'block lg:hidden cursor-pointer' : 'hidden'"
      ></div>
      <div class="min-h-screen w-full relative overflow-auto lg:ml-60">
        <div class="py-5 px-6">
          <router-view></router-view>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import BaseSidebar from "@/components/BaseSidebar";
import BaseNavbar from "@/components/BaseNavbar";

export default {
  components: { BaseNavbar, BaseSidebar },
  name: "BaseLayout",
  data() {
    return {
      ww: window.innerWidth,
    };
  },
  computed: {
    ...mapState({
      backdrop: (state) => state.menu.backdrop,
      sidebar: (state) => state.menu.sidebar,
    }),
  },
  created() {
    window.addEventListener("resize", this.windowResize);
  },
  destroyed() {
    window.removeEventListener("resize", this.windowResize);
  },
  methods: {
    ...mapActions("auth", ["LOGOUT"]),
    ...mapActions("menu", ["TOGGLE_SIDEBAR"]),
    windowResize() {
      let w = document.documentElement.clientWidth;
      let sb = this.$store.state.menu.sidebar;

      if (w < 1024) {
        this.ww = w;
        if (sb) this.TOGGLE_SIDEBAR();
      }

      if (w > 1023) {
        if (!sb) this.TOGGLE_SIDEBAR();
      }

      this.ww = w;
    },
    async toLogout() {
      let res = await this.LOGOUT("auth/LOGOUT");

      if (res) this.$router.push({ name: "login" });
    },
    hideSide() {
      this.TOGGLE_SIDEBAR();
    },
  },
};
</script>
