import ApiRequest from "../../services/apiRequest";

export const AREA_SUPPORT_LIST = async (context) => {
  return await ApiRequest.areaSupportIndex({
    per_page: 200,
  })
    .then((response) => {
      context.commit("AREA_SUPPORT_LIST", response.data.data);
      return response.data.data;
    })
    .catch((error) => {
      if (error) {
        // console.log(error)
      }
      return false;
    });
};

export const AREA_SUPPORT_INDEX = async (context, params) => {
  return await ApiRequest.areaSupportIndex(params)
    .then((response) => {
      context.commit("AREA_SUPPORT_INDEX", response.data.data);
      return response.data.meta;
    })
    .catch((error) => {
      if (error) {
        // console.log(error)
      }
      return false;
    });
};

export const AREA_SUPPORT_CREATE = async (context, data) => {
  return await ApiRequest.areaSupportCreate(data)
    .then((response) => {
      context.dispatch("menu/RESPONSE_NOTIFICATIONS", response.data, {
        root: true,
      });
      return true;
    })
    .catch((error) => {
      if (error) {
        context.dispatch("menu/RESPONSE_NOTIFICATIONS", error.response.data, {
          root: true,
        });
        return false;
      }
    });
};

export const AREA_SUPPORT_EDIT = async (context, data) => {
  return await ApiRequest.areaSupportEdit(data)
    .then((response) => {
      context.dispatch("menu/RESPONSE_NOTIFICATIONS", response.data, {
        root: true,
      });
      return true;
    })
    .catch((error) => {
      if (error) {
        context.dispatch("menu/RESPONSE_NOTIFICATIONS", error.response.data, {
          root: true,
        });
        return false;
      }
    });
};

export const AREA_SUPPORT_DELETE = async (context, data) => {
  return await ApiRequest.areaSupportDelete(data)
    .then((response) => {
      context.dispatch("menu/RESPONSE_NOTIFICATIONS", response.data, {
        root: true,
      });
      return true;
    })
    .catch((error) => {
      if (error) {
        context.dispatch("menu/RESPONSE_NOTIFICATIONS", error.response.data, {
          root: true,
        });
        return false;
      }
    });
};

// COLLECTORS
export const AREA_COLLECTOR_LIST = async (context, params) => {
  return await ApiRequest.areaCollectorIndex(params)
    .then((response) => {
      context.commit("AREA_COLLECTOR_LIST", response.data.data);
      return response.data.data;
    })
    .catch((error) => {
      if (error) {
        // console.log(error)
      }
      return false;
    });
};

export const AREA_COLLECTOR_INDEX = async (context, params) => {
  return await ApiRequest.areaCollectorIndex(params)
    .then((response) => {
      context.commit("AREA_COLLECTOR_INDEX", response.data.data);
      return response.data.meta;
    })
    .catch((error) => {
      if (error) {
        // console.log(error)
      }
      return false;
    });
};

export const AREA_COLLECTOR_CREATE = async (context, data) => {
  return await ApiRequest.areaCollectorCreate(data)
    .then((response) => {
      context.dispatch("menu/RESPONSE_NOTIFICATIONS", response.data, {
        root: true,
      });
      return true;
    })
    .catch((error) => {
      if (error) {
        context.dispatch("menu/RESPONSE_NOTIFICATIONS", error.response.data, {
          root: true,
        });
        return false;
      }
    });
};

export const AREA_COLLECTOR_EDIT = async (context, data) => {
  return await ApiRequest.areaCollectorEdit(data)
    .then((response) => {
      context.dispatch("menu/RESPONSE_NOTIFICATIONS", response.data, {
        root: true,
      });
      return true;
    })
    .catch((error) => {
      if (error) {
        context.dispatch("menu/RESPONSE_NOTIFICATIONS", error.response.data, {
          root: true,
        });
        return false;
      }
    });
};

export const AREA_COLLECTOR_DELETE = async (context, data) => {
  return await ApiRequest.areaCollectorDelete(data)
    .then((response) => {
      context.dispatch("menu/RESPONSE_NOTIFICATIONS", response.data, {
        root: true,
      });
      return true;
    })
    .catch((error) => {
      if (error) {
        context.dispatch("menu/RESPONSE_NOTIFICATIONS", error.response.data, {
          root: true,
        });
        return false;
      }
    });
};

// ASSETS
export const AREA_ASSET_LIST = async (context, params) => {
  return await ApiRequest.areaAssetIndex(params)
    .then((response) => {
      context.commit("AREA_ASSET_LIST", response.data.data);
      return response.data.data;
    })
    .catch((error) => {
      if (error) {
        // console.log(error)
      }
      return false;
    });
};

export const AREA_ASSET_INDEX = async (context, params) => {
  return await ApiRequest.areaAssetIndex(params)
    .then((response) => {
      context.commit("AREA_ASSET_INDEX", response.data.data);
      return response.data.meta;
    })
    .catch((error) => {
      if (error) {
        // console.log(error)
      }
      return false;
    });
};

export const AREA_ASSET_CREATE = async (context, data) => {
  return await ApiRequest.areaAssetCreate(data)
    .then((response) => {
      context.dispatch("menu/RESPONSE_NOTIFICATIONS", response.data, {
        root: true,
      });
      return true;
    })
    .catch((error) => {
      if (error) {
        context.dispatch("menu/RESPONSE_NOTIFICATIONS", error.response.data, {
          root: true,
        });
        return false;
      }
    });
};

export const AREA_ASSET_EDIT = async (context, data) => {
  return await ApiRequest.areaAssetEdit(data)
    .then((response) => {
      context.dispatch("menu/RESPONSE_NOTIFICATIONS", response.data, {
        root: true,
      });
      return true;
    })
    .catch((error) => {
      if (error) {
        context.dispatch("menu/RESPONSE_NOTIFICATIONS", error.response.data, {
          root: true,
        });
        return false;
      }
    });
};

export const AREA_ASSET_DELETE = async (context, data) => {
  return await ApiRequest.areaAssetDelete(data)
    .then((response) => {
      context.dispatch("menu/RESPONSE_NOTIFICATIONS", response.data, {
        root: true,
      });
      return true;
    })
    .catch((error) => {
      if (error) {
        context.dispatch("menu/RESPONSE_NOTIFICATIONS", error.response.data, {
          root: true,
        });
        return false;
      }
    });
};

// SALES
export const AREA_SALES_LIST = async (context, params) => {
  return await ApiRequest.areaSalesIndex(params)
    .then((response) => {
      context.commit("AREA_SALES_LIST", response.data.data);
      return response.data.data;
    })
    .catch((error) => {
      if (error) {
        // console.log(error)
      }
      return false;
    });
};

export const AREA_SALES_INDEX = async (context, params) => {
  return await ApiRequest.areaSalesIndex(params)
    .then((response) => {
      context.commit("AREA_SALES_INDEX", response.data.data);
      return response.data.meta;
    })
    .catch((error) => {
      if (error) {
        // console.log(error)
      }
      return false;
    });
};

export const AREA_SALES_CREATE = async (context, data) => {
  return await ApiRequest.areaSalesCreate(data)
    .then((response) => {
      context.dispatch("menu/RESPONSE_NOTIFICATIONS", response.data, {
        root: true,
      });
      return true;
    })
    .catch((error) => {
      if (error) {
        context.dispatch("menu/RESPONSE_NOTIFICATIONS", error.response.data, {
          root: true,
        });
        return false;
      }
    });
};

export const AREA_SALES_EDIT = async (context, data) => {
  return await ApiRequest.areaSalesEdit(data)
    .then((response) => {
      context.dispatch("menu/RESPONSE_NOTIFICATIONS", response.data, {
        root: true,
      });
      return true;
    })
    .catch((error) => {
      if (error) {
        context.dispatch("menu/RESPONSE_NOTIFICATIONS", error.response.data, {
          root: true,
        });
        return false;
      }
    });
};

export const AREA_SALES_DELETE = async (context, data) => {
  return await ApiRequest.areaSalesDelete(data)
    .then((response) => {
      context.dispatch("menu/RESPONSE_NOTIFICATIONS", response.data, {
        root: true,
      });
      return true;
    })
    .catch((error) => {
      if (error) {
        context.dispatch("menu/RESPONSE_NOTIFICATIONS", error.response.data, {
          root: true,
        });
        return false;
      }
    });
};

// DISTRICTS
export const DISTRICT_LIST = async (context, params) => {
  return await ApiRequest.districtIndex(params)
    .then((response) => {
      // context.commit("AREA_DISTRICT_LIST", response.data.data);
      return response.data.data;
    })
    .catch((error) => {
      if (error) {
        // console.log(error)
      }
      return false;
    });
};

// AREA PIVOTS

export const AREA_PIVOT_INDEX = async (context, params) => {
  return await ApiRequest.areaPivotIndex(params)
    .then((response) => {
      context.commit("AREA_PIVOT_INDEX", response.data.data);
      return response.data.meta;
    })
    .catch((error) => {
      if (error) {
        // console.log(error)
      }
      return false;
    });
};

export const AREA_PIVOT_CREATE = async (context, data) => {
  return await ApiRequest.areaPivotCreate(data)
    .then((response) => {
      context.dispatch("menu/RESPONSE_NOTIFICATIONS", response.data, {
        root: true,
      });
      return true;
    })
    .catch((error) => {
      if (error) {
        context.dispatch("menu/RESPONSE_NOTIFICATIONS", error.response.data, {
          root: true,
        });
        return false;
      }
    });
};

export const AREA_PIVOT_EDIT = async (context, data) => {
  return await ApiRequest.areaPivotEdit(data)
    .then((response) => {
      context.dispatch("menu/RESPONSE_NOTIFICATIONS", response.data, {
        root: true,
      });
      return true;
    })
    .catch((error) => {
      if (error) {
        context.dispatch("menu/RESPONSE_NOTIFICATIONS", error.response.data, {
          root: true,
        });
        return false;
      }
    });
};

export const AREA_PIVOT_DELETE = async (context, data) => {
  return await ApiRequest.areaPivotDelete(data)
    .then((response) => {
      context.dispatch("menu/RESPONSE_NOTIFICATIONS", response.data, {
        root: true,
      });
      return true;
    })
    .catch((error) => {
      if (error) {
        context.dispatch("menu/RESPONSE_NOTIFICATIONS", error.response.data, {
          root: true,
        });
        return false;
      }
    });
};
