export default function () {
  return {
    customers: [],
    customer: {},
    form: {},
    listGroups: [],
    listTypes: [],
    sales: [],
    locations: [],
    groups: [],
    types: [],
  };
}
