class TokenService {
  getAccessToken() {
    return localStorage.getItem("token");
  }
  setAccessToken(token) {
    localStorage.setItem("token", token);
    // localStorage.setItem('user', JSON.stringify(user))
  }
  removeToken() {
    localStorage.removeItem("token");
  }
}
export default new TokenService();
