export const listSupports = (state) => {
  let data = state.listSupports;

  let result = data.map((elm) => ({ id: elm.id, label: elm.name }));

  return result.sort((a, b) => (a.label > b.label ? 1 : -1));
};

export const listCollectors = (state) => {
  let data = state.listCollectors;

  let result = data.map((elm) => ({ id: elm.id, label: elm.name }));

  return result.sort((a, b) => (a.label > b.label ? 1 : -1));
};

export const listAssets = (state) => {
  let data = state.listAssets;

  let result = data.map((elm) => ({ id: elm.id, label: elm.name }));

  return result.sort((a, b) => (a.label > b.label ? 1 : -1));
};

export const listSales = (state) => {
  let data = state.listSales;
  let result = [];

  if (Array.isArray(data) && data.length) {
    data.forEach((d) => {
      let temp = {
        id: d.id,
        label: d.name,
        children: [],
      };

      if (Array.isArray(d.childrens) && d.childrens.length) {
        d.childrens.forEach((dc) => {
          temp.children.push({
            id: dc.id,
            label: dc.name,
          });
        });
      } else delete temp.children;

      result.push(temp);
    });
  }
  return result.sort((a, b) => (a.label > b.label ? 1 : -1));
};
