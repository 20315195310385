<template>
  <aside
    ref="sideBar"
    class="fixed z-20 h-full top-0 left-0 pt-12 lg:flex flex-shrink-0 flex-col w-64 transition-width duration-100"
    :class="
      sidebar
        ? 'flex bg-w-blue-3 lg:bg-transparent backdrop-blur-sm lg:backdrop-blur-none'
        : 'hidden'
    "
  >
    <div class="relative flex-1 flex flex-col min-h-0 pt-0">
      <div class="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
        <div class="flex-1 px-3 space-y-1">
          <div class="flex items-center">
            <button
              @click="toPage('home')"
              data-augmented-ui="br-clip"
              class="text-base group font-normal cyber-br w-full flex items-center py-2 px-3 hover:bg-w-cyber-4 duration-300"
              :class="
                menu.meta.group == 'home'
                  ? 'bg-w-cyber-blue-2 backdrop-blur-sm'
                  : ''
              "
            >
              <IconDashboard
                :class="
                  menu.meta.group == 'home'
                    ? 'fill-w-cyber-blue-1'
                    : 'fill-w-white-4'
                "
                class="w-5 h-5 transition duration-100 group-hover:fill-w-cyber-1"
              />
              <span
                class="ml-2 mt-1 font-medium group-hover:text-w-cyber-1 duration-100"
                :class="
                  menu.meta.group == 'home'
                    ? 'text-w-cyber-blue-1 font-semibold'
                    : 'text-w-white-4'
                "
              >
                Dashboard
              </span>
            </button>
          </div>

          <!-- areas -->
          <div class="flex items-center">
            <button
              data-augmented-ui="br-clip"
              @click="TOGGLE_SIDE_MASTER"
              class="relative text-base group cyber-br hover:bg-w-cyber-4 w-full flex items-center py-2 px-3 duration-300"
            >
              <IconData
                class="w-5 h-5 flex-shrink-0 transition duration-100 group-hover:fill-w-cyber-1 fill-w-white-4"
              />
              <span
                class="ml-2 text-w-white-4 group-hover:text-w-cyber-1 duration-100"
              >
                Master
              </span>
              <div class="absolute right-3 z-1">
                <IconCollapse
                  :class="sidemaster ? 'rotate-135' : 'rotate-0'"
                  class="h-4 w-4 text-w-white-2 duration-500 group-hover:text-w-cyber-1"
                />
              </div>
            </button>
          </div>
          <div
            class="grid gap-1 text-left ml-auto w-10/12 relative overflow-hidden transition-all max-h-0 duration-300"
            ref="sideMaster"
            :style="
              sm ? 'max-height: ' + $refs.sideMaster.scrollHeight + 'px' : ''
            "
          >
            <button
              @click="toPage('instrument.index')"
              data-augmented-ui="br-clip"
              class="text-base group hover:bg-w-cyber-4 w-full flex items-center py-1.5 px-3 cyber-br"
              :class="
                menu.meta.group == 'instruments'
                  ? 'bg-w-cyber-blue-2 backdrop-blur-sm'
                  : ''
              "
            >
              <span
                class="group-hover:text-w-cyber-1"
                :class="
                  menu.meta.group == 'instruments'
                    ? 'text-w-cyber-blue-1 font-semibold'
                    : 'text-w-white-4'
                "
              >
                Instrument
              </span>
            </button>
            <button
              @click="toPage('brand.index')"
              data-augmented-ui="br-clip"
              class="text-base group hover:bg-w-cyber-4 w-full flex items-center py-1.5 px-3 cyber-br"
              :class="
                menu.meta.group == 'brands'
                  ? 'bg-w-cyber-blue-2 backdrop-blur-sm'
                  : ''
              "
            >
              <span
                class="group-hover:text-w-cyber-1"
                :class="
                  menu.meta.group == 'brands'
                    ? 'text-w-cyber-blue-1 font-semibold'
                    : 'text-w-white-4'
                "
              >
                Principal
              </span>
            </button>
            <button
              @click="toPage('segment.index')"
              data-augmented-ui="br-clip"
              class="text-base group hover:bg-w-cyber-4 w-full flex items-center py-1.5 px-3 cyber-br"
              :class="
                menu.meta.group == 'segments'
                  ? 'bg-w-cyber-blue-2 backdrop-blur-sm'
                  : ''
              "
            >
              <span
                class="group-hover:text-w-cyber-1"
                :class="
                  menu.meta.group == 'segments'
                    ? 'text-w-cyber-blue-1 font-semibold'
                    : 'text-w-white-4'
                "
              >
                Segment
              </span>
            </button>
          </div>
          <!-- end areas -->
          <!-- customer -->
          <div class="flex items-center">
            <button
              data-augmented-ui="br-clip"
              @click="TOGGLE_SIDE_CUSTOMER"
              class="relative text-base group cyber-br hover:bg-w-cyber-4 w-full flex items-center py-2 px-3 duration-300"
            >
              <IconCare
                class="w-5 h-5 flex-shrink-0 transition duration-100 group-hover:fill-w-cyber-1 fill-w-white-4"
              />
              <span
                class="ml-2 text-w-white-4 group-hover:text-w-cyber-1 duration-100"
              >
                Customers
              </span>
              <div class="absolute right-3 z-1">
                <IconCollapse
                  :class="sidecustomer ? 'rotate-135' : 'rotate-0'"
                  class="h-4 w-4 text-w-white-2 duration-500 group-hover:text-w-cyber-1"
                />
              </div>
            </button>
          </div>
          <div
            class="grid gap-1 text-left ml-auto w-10/12 relative overflow-hidden transition-all max-h-0 duration-300"
            ref="sideCustomer"
            :style="
              sc ? 'max-height: ' + $refs.sideCustomer.scrollHeight + 'px' : ''
            "
          >
            <button
              @click="toPage('customer.index')"
              data-augmented-ui="br-clip"
              class="text-base group hover:bg-w-cyber-4 w-full flex items-center py-1.5 px-3 cyber-br"
              :class="
                menu.meta.group == 'customer'
                  ? 'bg-w-cyber-blue-2 backdrop-blur-sm'
                  : ''
              "
            >
              <span
                class="group-hover:text-w-cyber-1"
                :class="
                  menu.meta.group == 'customer'
                    ? 'text-w-cyber-blue-1 font-semibold'
                    : 'text-w-white-4'
                "
              >
                All Customer
              </span>
            </button>
            <button
              @click="toPage('customer.group.index')"
              data-augmented-ui="br-clip"
              class="text-base group hover:bg-w-cyber-4 w-full flex items-center py-1.5 px-3 cyber-br"
              :class="
                menu.meta.group == 'customer.group'
                  ? 'bg-w-cyber-blue-2 backdrop-blur-sm'
                  : ''
              "
            >
              <span
                class="group-hover:text-w-cyber-1"
                :class="
                  menu.meta.group == 'customer.group'
                    ? 'text-w-cyber-blue-1 font-semibold'
                    : 'text-w-white-4'
                "
              >
                Customer Group
              </span>
            </button>
            <button
              @click="toPage('customer.type.index')"
              data-augmented-ui="br-clip"
              class="text-base group hover:bg-w-cyber-4 w-full flex items-center py-1.5 px-3 cyber-br"
              :class="
                menu.meta.group == 'customer.type'
                  ? 'bg-w-cyber-blue-2 backdrop-blur-sm'
                  : ''
              "
            >
              <span
                class="group-hover:text-w-cyber-1"
                :class="
                  menu.meta.group == 'customer.type'
                    ? 'text-w-cyber-blue-1 font-semibold'
                    : 'text-w-white-4'
                "
              >
                Customer Type
              </span>
            </button>
          </div>
          <!-- end customer -->

          <!-- areas -->
          <div class="flex items-center">
            <button
              data-augmented-ui="br-clip"
              @click="TOGGLE_SIDE_AREA"
              class="relative text-base group cyber-br hover:bg-w-cyber-4 w-full flex items-center py-2 px-3 duration-300"
            >
              <IconMap
                class="w-5 h-5 flex-shrink-0 transition duration-100 group-hover:fill-w-cyber-1 hover:stroke-w-cyber-1 stroke-w-white-4 fill-w-white-4"
              />
              <span
                class="ml-2 text-w-white-4 group-hover:text-w-cyber-1 duration-100"
              >
                Area
              </span>
              <div class="absolute right-3 z-1">
                <IconCollapse
                  :class="sidearea ? 'rotate-135' : 'rotate-0'"
                  class="h-4 w-4 text-w-white-2 duration-500 group-hover:text-w-cyber-1"
                />
              </div>
            </button>
          </div>
          <div
            class="grid gap-1 text-left ml-auto w-10/12 relative overflow-hidden transition-all max-h-0 duration-300"
            ref="sideArea"
            :style="
              sa ? 'max-height: ' + $refs.sideArea.scrollHeight + 'px' : ''
            "
          >
            <button
              @click="toPage('area.support.index')"
              data-augmented-ui="br-clip"
              class="text-base group hover:bg-w-cyber-4 w-full flex items-center py-1.5 px-3 cyber-br"
              :class="
                menu.meta.group == 'area.support'
                  ? 'bg-w-cyber-blue-2 backdrop-blur-sm'
                  : ''
              "
            >
              <span
                class="group-hover:text-w-cyber-1"
                :class="
                  menu.meta.group == 'area.support'
                    ? 'text-w-cyber-blue-1 font-semibold'
                    : 'text-w-white-4'
                "
              >
                Supports
              </span>
            </button>
            <button
              @click="toPage('area.sales.index')"
              data-augmented-ui="br-clip"
              class="text-base group hover:bg-w-cyber-4 w-full flex items-center py-1.5 px-3 cyber-br"
              :class="
                menu.meta.group == 'area.sales'
                  ? 'bg-w-cyber-blue-2 backdrop-blur-sm'
                  : ''
              "
            >
              <span
                class="group-hover:text-w-cyber-1"
                :class="
                  menu.meta.group == 'area.sales'
                    ? 'text-w-cyber-blue-1 font-semibold'
                    : 'text-w-white-4'
                "
              >
                Sales
              </span>
            </button>
            <button
              @click="toPage('area.collector.index')"
              data-augmented-ui="br-clip"
              class="text-base group hover:bg-w-cyber-4 w-full flex items-center py-1.5 px-3 cyber-br"
              :class="
                menu.meta.group == 'area.collector'
                  ? 'bg-w-cyber-blue-2 backdrop-blur-sm'
                  : ''
              "
            >
              <span
                class="group-hover:text-w-cyber-1"
                :class="
                  menu.meta.group == 'area.collector'
                    ? 'text-w-cyber-blue-1 font-semibold'
                    : 'text-w-white-4'
                "
              >
                Collectors
              </span>
            </button>
            <button
              @click="toPage('area.asset.index')"
              data-augmented-ui="br-clip"
              class="text-base group hover:bg-w-cyber-4 w-full flex items-center py-1.5 px-3 cyber-br"
              :class="
                menu.meta.group == 'area.asset'
                  ? 'bg-w-cyber-blue-2 backdrop-blur-sm'
                  : ''
              "
            >
              <span
                class="group-hover:text-w-cyber-1"
                :class="
                  menu.meta.group == 'area.asset'
                    ? 'text-w-cyber-blue-1 font-semibold'
                    : 'text-w-white-4'
                "
              >
                Assets
              </span>
            </button>
            <button
              @click="toPage('area.pivot.index')"
              data-augmented-ui="br-clip"
              class="text-base group hover:bg-w-cyber-4 w-full flex items-center py-1.5 px-3 cyber-br"
              :class="
                menu.meta.group == 'area.pivot'
                  ? 'bg-w-cyber-blue-2 backdrop-blur-sm'
                  : ''
              "
            >
              <span
                class="group-hover:text-w-cyber-1"
                :class="
                  menu.meta.group == 'area.pivot'
                    ? 'text-w-cyber-blue-1 font-semibold'
                    : 'text-w-white-4'
                "
              >
                ALL
              </span>
            </button>
          </div>
          <!-- end areas -->
        </div>
      </div>
    </div>
  </aside>
</template>

<script>
import { mapActions, mapState } from "vuex";

import IconCollapse from "@/assets/IconCollapse.svg";
import IconData from "@/assets/IconData.svg";
import IconDashboard from "@/assets/IconDashboard.svg";
import IconCare from "@/assets/IconCare.svg";
import IconMap from "@/assets/IconMap.svg";

export default {
  name: "BaseSidebar",
  components: {
    IconCollapse,
    IconDashboard,
    IconData,
    IconCare,
    IconMap,
  },
  props: ["ww"],
  data() {
    return {
      customerReady: false,
      areaReady: false,
      masterReady: false,
    };
  },
  computed: {
    sidebar: {
      get: function () {
        return this.$store.state.menu.sidebar;
      },
      set: function () {
        this.TOGGLE_SIDEBAR();
      },
    },
    ...mapState({
      sidecustomer: (state) => state.menu.sideCustomer,
      sidearea: (state) => state.menu.sideArea,
      sidemaster: (state) => state.menu.sideMaster,
    }),
    menu: function () {
      return this.$route;
    },
    sc: function () {
      if (this.sidecustomer && this.customerReady) return true;
      else return false;
    },
    sa: function () {
      if (this.sidearea && this.areaReady) return true;
      else return false;
    },
    sm: function () {
      if (this.sidemaster && this.masterReady) return true;
      else return false;
    },
  },
  mounted() {
    const ic = setInterval(() => {
      if (this.$refs.sideCustomer) {
        this.customerReady = true;
        clearInterval(ic);
      }
    }, 50);

    const ia = setInterval(() => {
      if (this.$refs.sideArea) {
        this.areaReady = true;
        clearInterval(ia);
      }
    }, 50);

    const im = setInterval(() => {
      if (this.$refs.sideMaster) {
        this.masterReady = true;
        clearInterval(im);
      }
    }, 50);
  },
  methods: {
    ...mapActions("menu", [
      "TOGGLE_SIDEBAR",
      "TOGGLE_SIDE_CUSTOMER",
      "TOGGLE_SIDE_AREA",
      "TOGGLE_SIDE_MASTER",
    ]),
    toPage(val) {
      if (this.ww < 1024) this.TOGGLE_SIDEBAR();

      if (this.menu.name != val) this.$router.push({ name: val });
    },
  },
};
</script>
