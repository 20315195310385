<template>
  <div class="customers-add space-y-4">
    <div class="flex justify-between items-center pb-1">
      <div class="text-white font-semibold text-xl sm:text-3xl">
        Create {{ $route.meta.title }}
      </div>

      <button
        class="cyber-btn-primary px-3.5 py-1.5 font-medium text-w-cyber-blue-1 hover:bg-w-cyber-blue-2 duration-100"
        data-augmented-ui="br-clip"
        @click="$router.go(-1)"
      >
        Back_
      </button>
    </div>
    <div class="grid gap-3">
      <line-thin>
        <div class="z-5 relative p-4">
          <div class="grid gap-4 pb-2">
            <div class="w-full">
              <div class="form-group">
                <label
                  class="form-label bg-w-white-2 text-w-blue-2"
                  :class="{
                    'bg-w-cyber-red-1 text-w-cyber-red-2':
                      v$.form.district_id.$error,
                  }"
                >
                  District
                </label>
                <div
                  class="form-control h-9.5 absolute fc"
                  :class="
                    v$.form.district_id.$error
                      ? 'border-w-cyber-red-1 border before:border-b-2 before:border-w-cyber-red-1'
                      : 'border-w-white-2 border before:border-b-2 before:border-w-white-2'
                  "
                ></div>
                <treeselect
                  v-model="form.district_id"
                  :limit="1"
                  :clearable="false"
                  :multiple="true"
                  :appendToBody="true"
                  placeholder="Select District"
                  :async="true"
                  :load-options="loadOptions"
                  class="relative z-5 text-white text-sm placeholder-w-white-2 mt-0.5"
                />
              </div>
            </div>

            <div class="w-full">
              <div class="form-group">
                <label
                  class="form-label bg-w-white-2 text-w-blue-2"
                  :class="{
                    'bg-w-cyber-red-1 text-w-cyber-red-2':
                      v$.form.area_support_id.$error,
                  }"
                >
                  Support Area
                </label>
                <div
                  class="form-control h-9.5 absolute fc"
                  :class="
                    v$.form.area_support_id.$error
                      ? 'border-w-cyber-red-1 border before:border-b-2 before:border-w-cyber-red-1'
                      : 'border-w-white-2 border before:border-b-2 before:border-w-white-2'
                  "
                ></div>
                <treeselect
                  v-model="form.area_support_id"
                  :multiple="false"
                  :appendToBody="true"
                  placeholder="Select Support Area"
                  :options="listSupports"
                  class="relative z-5 text-white text-sm placeholder-w-white-2 mt-0.5"
                />
              </div>
            </div>

            <div class="w-full">
              <div class="form-group">
                <label
                  class="form-label bg-w-white-2 text-w-blue-2"
                  :class="{
                    'bg-w-cyber-red-1 text-w-cyber-red-2':
                      v$.form.area_ismart_id.$error,
                  }"
                >
                  Sales Area
                </label>
                <div
                  class="form-control h-9.5 absolute fc"
                  :class="
                    v$.form.area_ismart_id.$error
                      ? 'border-w-cyber-red-1 border before:border-b-2 before:border-w-cyber-red-1'
                      : 'border-w-white-2 border before:border-b-2 before:border-w-white-2'
                  "
                ></div>
                <treeselect
                  v-model="form.area_ismart_id"
                  :multiple="false"
                  :appendToBody="true"
                  placeholder="Select Sales Area"
                  :options="listSales"
                  class="relative z-5 text-white text-sm placeholder-w-white-2 mt-0.5"
                />
              </div>
            </div>

            <div class="w-full">
              <div class="form-group">
                <label
                  class="form-label bg-w-white-2 text-w-blue-2"
                  :class="{
                    'bg-w-cyber-red-1 text-w-cyber-red-2':
                      v$.form.area_ico_id.$error,
                  }"
                >
                  Collector Area
                </label>
                <div
                  class="form-control h-9.5 absolute fc"
                  :class="
                    v$.form.area_ico_id.$error
                      ? 'border-w-cyber-red-1 border before:border-b-2 before:border-w-cyber-red-1'
                      : 'border-w-white-2 border before:border-b-2 before:border-w-white-2'
                  "
                ></div>
                <treeselect
                  v-model="form.area_ico_id"
                  :multiple="false"
                  :appendToBody="true"
                  placeholder="Select Collector Area"
                  :options="listCollectors"
                  class="relative z-5 text-white text-sm placeholder-w-white-2 mt-0.5"
                />
              </div>
            </div>

            <div class="w-full">
              <div class="form-group">
                <label
                  class="form-label bg-w-white-2 text-w-blue-2"
                  :class="{
                    'bg-w-cyber-red-1 text-w-cyber-red-2':
                      v$.form.area_asset_id.$error,
                  }"
                >
                  Asset Area
                </label>
                <div
                  class="form-control h-9.5 absolute fc"
                  :class="
                    v$.form.area_asset_id.$error
                      ? 'border-w-cyber-red-1 border before:border-b-2 before:border-w-cyber-red-1'
                      : 'border-w-white-2 border before:border-b-2 before:border-w-white-2'
                  "
                ></div>
                <treeselect
                  v-model="form.area_asset_id"
                  :multiple="false"
                  :appendToBody="true"
                  placeholder="Select Aasset Area"
                  :options="listAssets"
                  class="relative z-5 text-white text-sm placeholder-w-white-2 mt-0.5"
                />
              </div>
            </div>
          </div>
        </div>
      </line-thin>
    </div>

    <div class="flex justify-end pb-6 pt-2">
      <button
        class="cybr-btn group pr-3 pl-4 py-1 tracking-widest z-1"
        type="submit"
        @click="toSave"
        :disabled="conn"
      >
        <span class="font-Orbitron"> Create<span aria-hidden>_</span> </span>
        <span aria-hidden class="cybr-btn__glitch font-Orbitron">Create_</span>
        <span aria-hidden class="cybr-btn__tag font-Orbitron">R25</span>
      </button>
    </div>
  </div>
</template>

<script>
import LineThin from "@/components/LineThin";
import { mapActions, mapGetters } from "vuex";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import Treeselect from "@riophae/vue-treeselect";

export default {
  name: "AreaPivotCreate",
  components: {
    LineThin,
    Treeselect,
  },
  data() {
    return {
      form: {
        district_id: null,
        area_support_id: null,
        area_ismart_id: null,
        area_ico_id: null,
        area_asset_id: null,
      },
      conn: false,
    };
  },
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    return {
      form: {
        district_id: {
          required,
        },
        area_support_id: {
          required,
        },
        area_ismart_id: {
          required,
        },
        area_ico_id: {
          required,
        },
        area_asset_id: {
          required,
        },
      },
    };
  },
  computed: {
    ...mapGetters("areas", [
      "listCollectors",
      "listAssets",
      "listSales",
      "listSupports",
    ]),
  },
  mounted() {
    this.AREA_SUPPORT_LIST({
      per_page: 100,
    });
    this.AREA_SALES_LIST({
      per_page: 100,
      level: 2,
    });

    this.AREA_COLLECTOR_LIST({
      per_page: 100,
    });
    this.AREA_ASSET_LIST({
      per_page: 100,
    });
  },
  methods: {
    ...mapActions("areas", [
      "AREA_PIVOT_CREATE",
      "AREA_COLLECTOR_LIST",
      "AREA_ASSET_LIST",
      "AREA_SUPPORT_LIST",
      "AREA_SALES_LIST",
      "DISTRICT_LIST",
    ]),

    async toSave() {
      this.conn = true;
      const result = await this.v$.$validate();

      if (!result) {
        this.conn = false;
        return;
      }
      let form = this.form;
      form.district_id = form.district_id.toString();

      let res = await this.AREA_PIVOT_CREATE(form);

      if (res) {
        this.$router.go(-1);
      }

      this.conn = false;
    },
    async loadOptions({ searchQuery, callback }) {
      const options = await this.DISTRICT_LIST({
        per_page: 20,
        search: searchQuery,
      });
      callback(null, options);
    },
  },
};
</script>
