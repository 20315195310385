<template>
  <ul class="pagination space-x-1 flex">
    <li class="hidden sm:inline-block">
      <button
        type="button"
        @click="onClickFirstPage"
        :disabled="isInFirstPage"
        data-augmented-ui="br-clip"
        class="text-w-white-4 text-sm px-2.5 py-1 cyber-br-sm"
        :class="
          isInFirstPage
            ? ''
            : 'hover:bg-w-cyber-blue-2 hover:text-w-cyber-1 hover:backdrop-blur-sm'
        "
      >
        First
      </button>
    </li>

    <li>
      <button
        type="button"
        @click="onClickPreviousPage"
        :disabled="isInFirstPage"
        data-augmented-ui="br-clip"
        class="text-w-white-4 text-sm px-2.5 py-1 cyber-br-sm"
        :class="
          isInFirstPage
            ? ''
            : 'hover:bg-w-cyber-blue-2 hover:text-w-cyber-1 hover:backdrop-blur-sm'
        "
      >
        Previous
      </button>
    </li>

    <li
      class="hidden sm:inline-block"
      v-for="(page, index) in pages"
      :key="index"
    >
      <button
        type="button"
        @click="onClickPage(page.name)"
        :disabled="page.isDisabled"
        data-augmented-ui="br-clip"
        class="text-w-white-4 cyber-br-sm h-8 w-8"
        :class="
          isPageActive(page.name)
            ? 'bg-w-cyber-blue-2 text-w-cyber-blue-1 backdrop-blur-sm font-semibold'
            : 'hover:bg-w-cyber-blue-2 hover:text-w-cyber-1 hover:backdrop-blur-sm'
        "
      >
        {{ page.name }}
      </button>
    </li>

    <li>
      <button
        type="button"
        @click="onClickNextPage"
        :disabled="isInLastPage"
        aria-label="Go to next page"
        data-augmented-ui="br-clip"
        class="text-w-white-4 text-sm px-2.5 py-1 cyber-br-sm"
        :class="
          isInLastPage
            ? ''
            : 'hover:bg-w-cyber-blue-2 hover:text-w-cyber-1 hover:backdrop-blur-sm'
        "
      >
        Next
      </button>
    </li>

    <li class="hidden sm:inline-block">
      <button
        type="button"
        @click="onClickLastPage"
        :disabled="isInLastPage"
        aria-label="Go to last page"
        data-augmented-ui="br-clip"
        class="text-w-white-4 text-sm px-2.5 py-1 cyber-br-sm"
        :class="
          isInLastPage
            ? ''
            : 'hover:bg-w-cyber-blue-2 hover:text-w-cyber-1 hover:backdrop-blur-sm'
        "
      >
        Last
      </button>
    </li>
  </ul>
</template>

<script>
export default {
  name: "BasePagination",
  props: {
    buttons: {
      type: Number,
      required: false,
      default: 3,
    },
    total_pages: {
      type: Number,
      required: true,
    },
    total: {
      type: Number,
      required: true,
    },
    per_page: {
      type: Number,
      required: true,
    },
    current_page: {
      type: Number,
      required: true,
    },
  },
  computed: {
    start_page() {
      if (this.current_page === 1) {
        return 1;
      }

      if (this.current_page === this.total_pages) {
        return this.total_pages - this.buttons + 1;
      }

      return this.current_page - 1;
    },
    end_page() {
      return Math.min(this.start_page + this.buttons - 1, this.total_pages);
    },
    pages() {
      const range = [];

      for (let i = this.start_page; i <= this.end_page; i += 1) {
        if (i > 0) {
          range.push({
            name: i,
            isDisabled: i === this.current_page,
          });
        }
      }

      return range;
    },
    isInFirstPage() {
      return this.current_page === 1;
    },
    isInLastPage() {
      return this.current_page === this.total_pages;
    },
  },
  methods: {
    onClickFirstPage() {
      this.$emit("pagechanged", 1);
    },
    onClickPreviousPage() {
      this.$emit("pagechanged", this.current_page - 1);
    },
    onClickPage(page) {
      this.$emit("pagechanged", page);
    },
    onClickNextPage() {
      this.$emit("pagechanged", this.current_page + 1);
    },
    onClickLastPage() {
      this.$emit("pagechanged", this.total_pages);
    },
    isPageActive(page) {
      return this.current_page === page;
    },
  },
};
</script>
