export const TOGGLE_SIDEBAR = async (context) => {
  context.commit("TOGGLE_SIDEBAR");
};

export const TOGGLE_PROMPT = async (context, val) => {
  context.commit("TOGGLE_PROMPT", val);
};

export const NOTIFICATIONS = async (context, val) => {
  context.commit("NOTIFICATIONS", val);
};

export const RESPONSE_NOTIFICATIONS = async (context, res) => {
  let data = {
    title: res.success ? "success" : "error",
    description: res.message,
    notifClass: res.success ? "notif_info" : "notif_error",
    notifContent: res.success ? "notif_content_info" : "notif_content_error",
    notifTitle: res.success ? "notif_title_primary" : "notif_title_error",
  };

  context.dispatch("NOTIFICATIONS", data);
};

export const REMOVE_NOTIFICATIONS = async (context) => {
  context.commit("REMOVE_NOTIFICATIONS");
};

export const TOGGLE_SIDE_CUSTOMER = async (context) => {
  context.commit("TOGGLE_SIDE_CUSTOMER");
};

export const TOGGLE_SIDE_AREA = async (context) => {
  context.commit("TOGGLE_SIDE_AREA");
};

export const TOGGLE_SIDE_MASTER = async (context) => {
  context.commit("TOGGLE_SIDE_MASTER");
};
