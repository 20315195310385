export default function () {
  return {
    listSupports: [],
    supports: [],
    collectors: [],
    listCollectors: [],
    assets: [],
    listAssets: [],
    sales: [],
    listSales: [],
    pivots: [],
  };
}
