import ErrorView from "@/views/ErrorView";
import BaseLayout from "@/layouts/BaseLayout";
import LoginView from "@/views/LoginView";
import HomeView from "@/views/HomeView";

import CustomerIndex from "@/views/customers/CustomerIndex";
import CustomerCreate from "@/views/customers/CustomerCreate";
import CustomerEdit from "@/views/customers/CustomerEdit";
import CustomerShow from "@/views/customers/CustomerShow";

import CustomerGroupIndex from "@/views/customers/groups/GroupIndex";
import CustomerGroupCreate from "@/views/customers/groups/GroupCreate";
import CustomerGroupEdit from "@/views/customers/groups/GroupEdit";

import CustomerTypeIndex from "@/views/customers/types/TypeIndex";
import CustomerTypeEdit from "@/views/customers/types/TypeEdit";
import CustomerTypeCreate from "@/views/customers/types/TypeCreate";

import InstrumentIndex from "@/views/masters/instruments/InstrumentIndex";

import BrandIndex from "@/views/masters/brands/BrandIndex";
import BrandCreate from "@/views/masters/brands/BrandCreate";
import BrandEdit from "@/views/masters/brands/BrandEdit";

import SegmentIndex from "@/views/masters/segments/SegmentIndex";
import SegmentCreate from "@/views/masters/segments/SegmentCreate";
import SegmentEdit from "@/views/masters/segments/SegmentEdit";

import AreaSupportCreate from "@/views/areas/supports/AreaSupportCreate";
import AreaSupportEdit from "@/views/areas/supports/AreaSupportEdit";
import AreaSupportIndex from "@/views/areas/supports/AreaSupportIndex";

import AreaCollectorCreate from "@/views/areas/collectors/AreaCollectorCreate";
import AreaCollectorEdit from "@/views/areas/collectors/AreaCollectorEdit";
import AreaCollectorIndex from "@/views/areas/collectors/AreaCollectorIndex";

import AreaAssetCreate from "@/views/areas/assets/AreaAssetCreate";
import AreaAssetEdit from "@/views/areas/assets/AreaAssetEdit";
import AreaAssetIndex from "@/views/areas/assets/AreaAssetIndex";

import AreaSalesCreate from "@/views/areas/sales/AreaSalesCreate";
import AreaSalesEdit from "@/views/areas/sales/AreaSalesEdit";
import AreaSalesIndex from "@/views/areas/sales/AreaSalesIndex";

import AreaPivotCreate from "@/views/areas/pivots/AreaPivotCreate";
import AreaPivotEdit from "@/views/areas/pivots/AreaPivotEdit";
import AreaPivotIndex from "@/views/areas/pivots/AreaPivotIndex";

import LockScreen from "@/views/LockScreen";

const routes = [];

routes.push({
  path: "/",
  redirect: "/home",
});

routes.push({
  path: "/login",
  name: "login",
  meta: {
    title: "Login",
  },
  component: LoginView,
});

routes.push({
  path: "/lockscreen",
  name: "lock",
  meta: {
    title: "Lock Screen",
  },
  component: LockScreen,
});

routes.push({
  path: "/",
  component: BaseLayout,
  meta: { auth: true },
  children: [
    {
      path: "home",
      name: "home",
      meta: {
        title: "Home",
        group: "home",
      },
      component: HomeView,
    },
    //customers
    {
      path: "customers",
      name: "customer.index",
      props(route) {
        return route.query || {};
      },
      meta: {
        title: "Customer",
        group: "customer",
      },
      component: CustomerIndex,
    },
    {
      path: "customers/create",
      name: "customer.create",
      meta: {
        title: "Customer",
        group: "customer",
      },
      component: CustomerCreate,
    },
    {
      path: "customers/edit/:number",
      name: "customer.edit",
      meta: {
        title: "Customer",
        group: "customer",
      },
      component: CustomerEdit,
    },
    {
      path: "customers/show/:number",
      name: "customer.show",
      meta: {
        title: "Customer",
        group: "customer",
      },
      component: CustomerShow,
    },
    // customer groups
    {
      path: "customer-groups",
      name: "customer.group.index",
      props(route) {
        return route.query || {};
      },
      meta: {
        title: "Customer Group",
        group: "customer.group",
      },
      component: CustomerGroupIndex,
    },
    {
      path: "customer-groups/create",
      name: "customer.group.create",
      meta: {
        title: "Customer Group",
        group: "customer.group",
      },
      component: CustomerGroupCreate,
    },
    {
      path: "customer-groups/edit/:id/:name",
      name: "customer.group.edit",
      meta: {
        title: "Customer Group",
        group: "customer.group",
      },
      component: CustomerGroupEdit,
    },
    // customer types
    {
      path: "customer-types",
      name: "customer.type.index",
      props(route) {
        return route.query || {};
      },
      meta: {
        title: "Customer Type",
        group: "customer.type",
      },
      component: CustomerTypeIndex,
    },
    {
      path: "customer-types/create",
      name: "customer.type.create",
      meta: {
        title: "Customer Type",
        group: "customer.type",
      },
      component: CustomerTypeCreate,
    },
    {
      path: "customer-types/edit/:id/:name",
      name: "customer.type.edit",
      meta: {
        title: "Customer Type",
        group: "customer.type",
      },
      component: CustomerTypeEdit,
    },
    // area supports
    {
      path: "area/supports",
      name: "area.support.index",
      props(route) {
        return route.query || {};
      },
      meta: {
        title: "Support Area",
        group: "area.support",
      },
      component: AreaSupportIndex,
    },
    {
      path: "area/supports/create",
      name: "area.support.create",
      meta: {
        title: "Support Area",
        group: "area.support",
      },
      component: AreaSupportCreate,
    },
    {
      path: "area/supports/edit/:id/:name",
      name: "area.support.edit",
      meta: {
        title: "Support Area",
        group: "area.support",
      },
      component: AreaSupportEdit,
    },

    // area sales
    {
      path: "area/sales",
      name: "area.sales.index",
      props(route) {
        return route.query || {};
      },
      meta: {
        title: "Sales Area",
        group: "area.sales",
      },
      component: AreaSalesIndex,
    },
    {
      path: "area/sales/create",
      name: "area.sales.create",
      meta: {
        title: "Sales Area",
        group: "area.sales",
      },
      component: AreaSalesCreate,
    },
    {
      path: "area/sales/edit/:id/:name",
      name: "area.sales.edit",
      meta: {
        title: "Sales Area",
        group: "area.sales",
      },
      component: AreaSalesEdit,
    },
    // area collectors
    {
      path: "area/collectors",
      name: "area.collector.index",
      props(route) {
        return route.query || {};
      },
      meta: {
        title: "Collector Area",
        group: "area.collector",
      },
      component: AreaCollectorIndex,
    },
    {
      path: "area/collectors/create",
      name: "area.collector.create",
      meta: {
        title: "Collector Area",
        group: "area.collector",
      },
      component: AreaCollectorCreate,
    },
    {
      path: "area/collectors/edit/:id/:name",
      name: "area.collector.edit",
      meta: {
        title: "Collector Area",
        group: "area.collector",
      },
      component: AreaCollectorEdit,
    },
    // area assets
    {
      path: "area/assets",
      name: "area.asset.index",
      props(route) {
        return route.query || {};
      },
      meta: {
        title: "Asset Area",
        group: "area.asset",
      },
      component: AreaAssetIndex,
    },
    {
      path: "area/assets/create",
      name: "area.asset.create",
      meta: {
        title: "Asset Area",
        group: "area.asset",
      },
      component: AreaAssetCreate,
    },
    {
      path: "area/assets/edit/:id/:name",
      name: "area.asset.edit",
      meta: {
        title: "Asset Area",
        group: "area.asset",
      },
      component: AreaAssetEdit,
    },
    // area pivots
    {
      path: "area/pivots",
      name: "area.pivot.index",
      props(route) {
        return route.query || {};
      },
      meta: {
        title: "Cross Area",
        group: "area.pivot",
      },
      component: AreaPivotIndex,
    },
    {
      path: "area/pivots/create",
      name: "area.pivot.create",
      meta: {
        title: "Cross Area",
        group: "area.pivot",
      },
      component: AreaPivotCreate,
    },
    {
      path: "area/pivots/edit/:id",
      name: "area.pivot.edit",
      meta: {
        title: "Cross Area",
        group: "area.pivot",
      },
      component: AreaPivotEdit,
    },
    // instruments
    {
      path: "master/instruments",
      name: "instrument.index",
      meta: {
        title: "Instrument",
        group: "instruments",
      },
      component: InstrumentIndex,
    },
    // principals
    {
      path: "master/brands",
      name: "brand.index",
      meta: {
        title: "Principal",
        group: "brands",
      },
      component: BrandIndex,
    },
    {
      path: "master/brands/create",
      name: "brand.create",
      meta: {
        title: "Principal",
        group: "brands",
      },
      component: BrandCreate,
    },
    {
      path: "master/brands/edit/:id/:name",
      name: "brand.edit",
      meta: {
        title: "Principal",
        group: "brands",
      },
      component: BrandEdit,
    },
    // segments
    {
      path: "master/segments",
      name: "segment.index",
      meta: {
        title: "Segment",
        group: "Segments",
      },
      component: SegmentIndex,
    },
    {
      path: "master/segments/create",
      name: "segment.create",
      meta: {
        title: "Segment",
        group: "segments",
      },
      component: SegmentCreate,
    },
    {
      path: "masters/segments/edit/:id/:name",
      name: "segment.edit",
      meta: {
        title: "Segment",
        group: "segments",
      },
      component: SegmentEdit,
    },
  ],
});

routes.push(
  // Always leave this as last one,
  // but you can also remove it
  {
    path: "*",
    component: ErrorView,
    meta: {
      title: "Error404",
      group: null,
    },
  }
);

export default routes;
