<template>
  <div class="home">
    <div class="w-1/2">
      <div
        class="w-full shadow-2xl subpixel-antialiased rounded h-64 bg-black border-black mx-auto"
      >
        <div
          class="flex items-center h-6 rounded-t bg-gray-100 border-b border-gray-500 text-center text-black"
          id="headerTerminal"
        >
          <div
            class="flex ml-2 items-center text-center border-red-900 bg-red-500 hover:bg-red-400 shadow-inner rounded-full w-3 h-3"
            id="closebtn"
          ></div>
          <div
            class="ml-2 border-yellow-900 bg-yellow-500 hover:bg-yellow-400 shadow-inner rounded-full w-3 h-3"
            id="minbtn"
          ></div>
          <div
            class="ml-2 border-green-900 bg-green-500 hover:bg-green-400 shadow-inner rounded-full w-3 h-3"
            id="maxbtn"
          ></div>
          <div class="mx-auto pr-16" id="terminaltitle">
            <p class="text-center text-sm">Terminal</p>
          </div>
        </div>
        <div
          class="pl-1 pt-1 h-auto text-green-200 font-medium text-sm bg-black"
          id="console"
        >
          <p>Last login: Today</p>
          <p v-for="(p, idx) in commands" :key="idx">{{ p }}</p>
          <p class="pb-1">
            warehouse:project$<input
              type="text"
              class="bg-black text-green-500 focus:border-0 focus:outline-0 ml-1"
              autofocus
              v-model="command"
              v-on:keyup.enter="onEnter"
            />
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "HomeView",
  data() {
    return {
      commands: [],
      command: "",
      routes: [
        "customer.index",
        "customer.create",
        "customer.show",
        "customer.group.index",
        "customer.group.create",
        "customer.type.index",
        "customer.type.create",
        "area.support.index",
        "area.support.create",
        "lock",
        "logout",
      ],
    };
  },
  methods: {
    ...mapActions("auth", ["LOGOUT"]),
    onEnter() {
      let routes = this.routes;
      let val = this.command;

      if (routes.includes(val)) {
        if (val == "logout") this.toLogout();
        else this.$router.push({ name: val });
      } else {
        if (this.command.length)
          this.commands.push(`warehouse:project$ command not found: ${val}`);
        else this.commands.push(" ");
        this.command = "";
      }
    },
    async toLogout() {
      let res = await this.LOGOUT();
      if (res === true) this.$router.push({ name: "login" });
    },
  },
};
</script>
