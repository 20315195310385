<template>
  <nav class="bg-w-blue-2 fixed z-30 w-full backdrop-blur-sm">
    <div class="px-3 py-2 lg:px-5 lg:pl-3">
      <div class="flex items-center justify-between">
        <div class="flex items-center justify-start">
          <button
            @click="TOGGLE_SIDEBAR"
            data-augmented-ui="br-clip"
            class="lg:hidden mr-2 p-0.5 text-white hover:text-w-cyber-1 cursor-pointer hover:bg-w-cyber-blue-2 focus:bg-w-cyber-blue-2 cyber-br-sm duration-100"
          >
            <svg
              class="ham hamRotate ham1 stroke-white hover:stroke-w-cyber-1 duration-100"
              viewBox="0 0 100 100"
              width="32"
              ref="ham"
            >
              <path
                class="line top"
                d="m 30,33 h 40 c 0,0 9.044436,-0.654587 9.044436,-8.508902 0,-7.854315 -8.024349,-11.958003 -14.89975,-10.85914 -6.875401,1.098863 -13.637059,4.171617 -13.637059,16.368042 v 40"
              />
              <path class="line middle" d="m 30,50 h 40" />
              <path
                class="line bottom"
                d="m 30,67 h 40 c 12.796276,0 15.357889,-11.717785 15.357889,-26.851538 0,-15.133752 -4.786586,-27.274118 -16.667516,-27.274118 -11.88093,0 -18.499247,6.994427 -18.435284,17.125656 l 0.252538,40"
              />
            </svg>
          </button>
          <span
            class="text-xl font-Orbitron tracking-wider font-bold flex items-center lg:ml-2.5"
          >
            <!-- <img
              src="https://demo.themesberg.com/windster/images/logo.svg"
              class="h-6 mr-2"
              alt="Windster Logo"
            /> -->
            <span class="self-center whitespace-nowrap text-white"
              >Warehouse</span
            >
          </span>
        </div>
        <div class="relative">
          <button
            data-augmented-ui="br-clip"
            class="flex items-center cyber-br space-x-2 justify-between px-3 py-2 w-full hover:bg-w-cyber-blue-2 text-white hover:text-w-cyber-1 duration-100"
            @click="toLogout"
          >
            <span class="font-semibold text-sm">{{ user.name }}</span>
            <span class="inline-flex justify-center items-center">
              <svg
                class="w-5 h-5"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                ></path>
              </svg>
            </span>
          </button>
          <!-- <transition
            enter-active-class="transform transition duration-500 ease-custom"
            enter-class="-translate-y-1/2 scale-y-0 opacity-0"
            enter-to-class="translate-y-0 scale-y-100 opacity-100"
            leave-active-class="transform transition duration-300 ease-custom"
            leave-class="translate-y-0 scale-y-100 opacity-100"
            leave-to-class="-translate-y-1/2 scale-y-0 opacity-0"
          >
            <ul
              v-show="isOptionsExpanded"
              class="absolute origin-top-right right-0 mt-1 bg-w-blue-1 backdrop-blur-sm rounded-lg overflow-hidden"
            >
              <li>
                <button
                  @click="toLogout"
                  class="px-3 py-2 text-white transition-colors duration-300 hover:bg-gray-200 hover:text-gray-800"
                >
                  Logout
                </button>
              </li>
            </ul>
          </transition> -->
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "BaseNavbar",
  props: ["ww"],
  data() {
    return {
      btn: false,
    };
  },
  computed: {
    ...mapState({
      sidebar: (state) => state.menu.sidebar,
      user: (state) => state.auth.user,
    }),
  },
  watch: {
    sidebar: function (val) {
      let ham = this.$refs.ham;

      if (val && this.ww < 1024) ham.classList.toggle("active");

      if (!val && this.ww < 1024) ham.classList.toggle("active");
    },
  },
  methods: {
    ...mapActions("menu", ["TOGGLE_SIDEBAR"]),
    ...mapActions("auth", ["LOGOUT"]),
    async toLogout() {
      let res = await this.LOGOUT();
      if (res === true) this.$router.push({ name: "login" });
    },
  },
};
</script>
