export default function () {
  return {
    instrument: {
      datatables: [],
    },
    brand: {
      lists: [],
      datatables: [],
    },
    segment: {
      datatables: [],
    },
  };
}
