<template>
  <div class="customers space-y-7">
    <div class="flex justify-between items-center">
      <div class="text-white font-semibold text-3xl">
        {{ $route.meta.title }}
      </div>

      <!-- <button
        data-augmented-ui="br-clip"
        class="cyber-btn-primary px-3.5 py-1.5 font-medium text-w-cyber-blue-1 hover:bg-w-cyber-blue-2"
        variant="primary"
        @click="toAdd"
      >
        Create_
      </button> -->
    </div>

    <line-thin>
      <div class="z-10 relative">
        <div class="p-4 space-y-4">
          <div class="grid sm:grid-cols-3 gap-3">
            <div class="col-span-1">
              <div class="form-group">
                <div
                  class="form-control form-control--with-addon border-[#9f9f9f] border before:border-b-2 before:border-[#9f9f9f]"
                >
                  <input
                    v-model="search"
                    v-debounce:300ms="toSearch"
                    placeholder="Search..."
                    type="text"
                    class="text-white placeholder-w-white-2"
                  />
                  <div class="form-control__addon form-control__addon--prefix">
                    <IconSearch />
                  </div>
                </div>
              </div>
            </div>
            <div class="col-span-1">
              <div class="form-group">
                <div
                  class="form-control h-8.2 absolute fc border-w-white-2 border before:border-b-2 before:border-w-white-2"
                ></div>
                <treeselect
                  v-model="brand_id"
                  :multiple="false"
                  zIndex="20"
                  placeholder="Principal"
                  :options="brandList"
                  class="relative z-5 text-white placeholder-w-white-4"
                />
              </div>
            </div>
          </div>

          <div class="space-y-2 relative overflow-x-auto pb-2">
            <div v-if="loading" class="w-full flex justify-center py-4">
              <div
                class="relative z-10 glitch-effect text-xl font-Orbitron font-bold tracking-wide text-w-cyber-1 opacity-90"
                title="Loading..."
              >
                Loading...
              </div>
            </div>

            <table v-if="!loading" class="w-full text-left text-white">
              <thead
                class="text-gray-200 font-medium capitalize bg-transparent"
              >
                <tr class="border-b border-w-white-2">
                  <th scope="col" class="py-2 px-3">No</th>
                  <th scope="col" class="py-2 px-3">Code</th>
                  <th scope="col" class="py-2 px-3">Principal</th>
                  <th scope="col" class="py-2 px-3">Name</th>
                  <th scope="col" class="py-2 px-3">Segment</th>
                  <!-- <th scope="col" class="py-2 px-3 text-center">Action</th> -->
                </tr>
              </thead>
              <tbody>
                <tr
                  class="border-b border-w-white-2 transition duration-300 ease-in-out hover:bg-w-blue-3"
                  v-for="(val, idx) in datatables"
                  :key="idx"
                >
                  <td class="py-2 px-3 text-w-white-4">
                    <span v-if="pagination">
                      {{ (currentPage - 1) * pagination.per_page + idx + 1 }}
                    </span>
                  </td>
                  <td class="py-2 px-3 text-w-white-4">
                    {{ val.code_number }}
                  </td>
                  <td class="py-2 px-3 text-w-white-4">
                    {{ val.brand_name }}
                  </td>
                  <td class="py-2 px-3 text-w-white-4">
                    {{ val.name }}
                  </td>
                  <td class="py-2 px-3 text-w-white-4">
                    {{ val.segment_name }}
                  </td>
                  <!-- <td class="py-2 px-3">
                    <div class="flex space-x-2 justify-center">
                      <button
                        @click="toEdit(val)"
                        data-augmented-ui="br-clip"
                        class="cyber-btn-secondary h-8.1 w-8.1 text-w-cyber-blue-1 hover:bg-w-cyber-blue-2"
                      >
                        <IconEdit
                          class="fill-w-cyber-blue-1 h-4 mx-auto -mt-1"
                        />
                      </button>
                      <button
                        @click="toDelete(val)"
                        data-augmented-ui="br-clip"
                        class="cyber-btn-danger h-8.1 w-8.1 text-w-cyber-red-1 hover:bg-w-cyber-red-2"
                      >
                        <IconDelete
                          class="h-3.5 mx-auto -mt-1 stroke-w-cyber-red-1 fill-w-cyber-red-1"
                        />
                      </button>
                    </div>
                  </td> -->
                </tr>
              </tbody>
            </table>
          </div>
          <div class="flex justify-between" v-if="pagination">
            <show-entries :current="currentPage" :pagination="pagination" />
            <pagination
              :total_pages="pagination.total_page"
              :total="pagination.total"
              :per_page="pagination.per_page"
              :current_page="currentPage"
              @pagechanged="onPageChange"
            />
          </div>
        </div>
      </div>
    </line-thin>

    <base-prompt v-if="prompt && prompt.type == 'delete'">
      <button
        data-augmented-ui="br-clip"
        class="cyber-btn-primary pl-2.5 pt-1 pb-1.5 pr-3 font-semibold text-w-cyber-blue-1 hover:bg-w-cyber-blue-2 duration-100"
        @click="confirmDelete"
      >
        OK_
      </button>
    </base-prompt>
  </div>
</template>

<script>
import LineThin from "@/components/LineThin";
import Pagination from "@/components/BasePagination";
import Treeselect from "@riophae/vue-treeselect";
import { mapActions, mapState } from "vuex";
import BasePrompt from "@/components/BasePrompt";
import ShowEntries from "@/components/ShowEntries";

// import IconEdit from "@/assets/IconEdit.svg";
// import IconDelete from "@/assets/IconDelete.svg";
import IconSearch from "@/assets/IconSearchFix.svg";

export default {
  components: {
    LineThin,
    Pagination,
    BasePrompt,
    ShowEntries,
    // IconEdit,
    // IconDelete,
    IconSearch,
    Treeselect,
  },
  name: "InstrumentIndex",
  data() {
    return {
      currentPage: 1,
      loading: true,
      form: {},
      pagination: null,
      search: "",
      brand_id: null,
    };
  },
  computed: {
    ...mapState({
      datatables: (state) => state.masters.instrument.datatables,
      brandList: (state) => state.masters.brand.lists,
      prompt: (state) => state.menu.prompt,
    }),
  },
  watch: {
    "$route.query": {
      deep: true,
      handler() {
        this.getData();
      },
    },
    brand_id: function (val) {
      this.searchFn("brand_id", val);
    },
  },
  mounted() {
    this.firstLoad();
    this.BRAND_LISTS();
  },
  methods: {
    ...mapActions("masters", [
      "INSTRUMENT_INDEX",
      "INSTRUMENT_DELETE",
      "BRAND_LISTS",
    ]),
    ...mapActions("menu", ["TOGGLE_PROMPT", "NOTIFICATIONS"]),
    firstLoad() {
      let queries = JSON.parse(JSON.stringify(this.$route.query));

      if (queries.page) this.currentPage = parseInt(queries.page);

      if (queries.search) this.search = queries.search;

      queries.t = new Date().getTime();

      this.$router.replace({ query: queries });
    },
    onPageChange(page) {
      this.currentPage = page;
      let queries = JSON.parse(JSON.stringify(this.$route.query));
      queries.t = new Date().getTime();
      queries.page = page;

      this.$router.push({ query: queries });
    },
    async getData() {
      this.loading = true;
      let params = Object.assign({}, this.$route.query);

      let meta = await this.INSTRUMENT_INDEX(params);
      if (meta) this.pagination = meta;

      setTimeout(() => (this.loading = false), 500);
    },
    toSearch(val) {
      this.searchFn("search", val);
    },
    searchFn(text, val) {
      let queries = JSON.parse(JSON.stringify(this.$route.query));
      queries.t = new Date().getTime();
      queries.page = 1;
      queries[text] = val;
      this.currentPage = 1;

      this.$router.replace({ query: queries });
    },
    toAdd() {
      this.$router.push({ name: "instrument.create" });
    },
    toDelete(val) {
      this.form = val;
      let form = val;
      form.prompt = "Confirm Delete";
      form.type = "delete";
      form.description = `Are you sure you want to delete <span class='font-semibold text-w-cyber-pink-1'>${val.name}</span>? <br/> You can't undo this action.`;

      this.TOGGLE_PROMPT(val);
    },
    async confirmDelete() {
      this.TOGGLE_PROMPT(null);
      await this.INSTRUMENT_DELETE(this.form.id);

      this.getData();
    },
    async toEdit(val) {
      //   await this.CUSTOMER_FORM(val);
      this.$router.push({
        name: "instrument.edit",
        params: { id: val.id, name: val.name },
      });
    },
  },
};
</script>
