export const TOGGLE_SIDEBAR = (state) => {
  state.sidebar = !state.sidebar;

  if (state.sidebar === true) state.backdrop = true;
  else state.backdrop = false;
};

export const TOGGLE_PROMPT = (state, val) => {
  state.prompt = val;

  if (!val) state.promptBackdrop = true;
  else state.promptBackdrop = false;
};

export const NOTIFICATIONS = (state, val) => {
  // let data = state.notifications;

  state.notifications.unshift(val);
};

export const REMOVE_NOTIFICATIONS = (state) => {
  if (state.notifications.length) {
    let idx = state.notifications[state.notifications.length - 1];
    state.notifications.splice(idx, 1);
  }
};

export const TOGGLE_SIDE_CUSTOMER = (state) => {
  state.sideCustomer = !state.sideCustomer;
};

export const TOGGLE_SIDE_AREA = (state) => {
  state.sideArea = !state.sideArea;
};

export const TOGGLE_SIDE_MASTER = (state) => {
  state.sideMaster = !state.sideMaster;
};
