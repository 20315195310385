<template>
  <div class="customers-edit space-y-4">
    <div class="flex justify-between items-center pb-1">
      <div class="text-white font-semibold text-xl sm:text-3xl">
        {{ $route.meta.title }}
      </div>

      <button
        class="cyber-btn-primary px-3.5 py-1.5 font-medium text-w-cyber-blue-1 hover:bg-w-cyber-blue-2 duration-100"
        data-augmented-ui="br-clip"
        @click="$router.go(-1)"
      >
        Back_
      </button>
    </div>
    <div v-if="loading">
      <transition
        enter-active-class="transform transition duration-300 ease-out"
        enter-class="-translate-y-1/2 scale-y-0 opacity-0"
        enter-to-class="translate-y-0 scale-y-100 opacity-100"
        leave-active-class="transform transition duration-400 ease-out"
        leave-class="translate-y-0 scale-y-100 opacity-100"
        leave-to-class="-translate-y-1/2 scale-y-0 opacity-0"
      >
        <line-thin class="w-full">
          <div class="flex justify-center py-4">
            <div
              class="relative z-10 glitch-effect text-xl font-Orbitron font-bold tracking-wide text-w-cyber-1 opacity-90"
              title="Loading..."
            >
              Loading...
            </div>
          </div>
        </line-thin>
      </transition>
    </div>
    <transition
      enter-active-class="transform transition duration-300 ease-out"
      enter-class="-translate-y-1/2 scale-y-0 opacity-0"
      enter-to-class="translate-y-0 scale-y-100 opacity-100"
      leave-active-class="transform transition duration-400 ease-out"
      leave-class="translate-y-0 scale-y-100 opacity-100"
      leave-to-class="-translate-y-1/2 scale-y-0 opacity-0"
    >
      <div class="space-y-3" v-if="!loading">
        <div class="grid sm:grid-cols-2 gap-3">
          <line-thin>
            <div class="z-10 relative p-4">
              <div class="grid gap-3">
                <div class="w-full">
                  <div class="form-group">
                    <label class="form-label bg-w-white-2 text-w-blue-2">
                      Number
                    </label>
                    <div
                      class="form-control py-1.5 px-3 text-gray-100 border-w-white-2 border before:border-b-2 before:border-w-white-2"
                    >
                      {{ customer.number }}
                    </div>
                  </div>
                </div>

                <div class="w-full">
                  <div class="form-group">
                    <label class="form-label bg-w-white-2 text-w-blue-2">
                      Name
                    </label>
                    <div
                      class="form-control py-1.5 px-3 text-gray-100 border-w-white-2 border before:border-b-2 before:border-w-white-2"
                    >
                      {{ customer.name }}
                    </div>
                  </div>
                </div>

                <div class="w-full">
                  <div class="form-group">
                    <label class="form-label bg-w-white-2 text-w-blue-2">
                      Phone Number
                    </label>
                    <div
                      class="form-control py-1.5 px-3 text-gray-100 border-w-white-2 border before:border-b-2 before:border-w-white-2"
                    >
                      {{ customer.phone_number }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </line-thin>
          <line-thin>
            <div class="relative border-b border-w-white-2">
              <div
                class="bg-white absolute top-0 left-0 w-full h-full opacity-10"
              ></div>
              <div class="text-white font-semibold z-10 relative px-4 py-2">
                Area
              </div>
            </div>
            <div class="z-10 relative space-y-4 p-4">
              <div class="grid gap-3">
                <div class="w-full">
                  <div class="form-group">
                    <label class="form-label bg-w-white-2 text-w-blue-2">
                      Area Support
                    </label>
                    <div
                      class="form-control py-1.5 px-3 text-gray-100 border-w-white-2 border before:border-b-2 before:border-w-white-2"
                    >
                      <span>{{
                        customer.area_support_id
                          ? customer.area_support.name
                          : "-"
                      }}</span>
                    </div>
                  </div>
                </div>
                <div class="w-full">
                  <div class="form-group">
                    <label class="form-label bg-w-white-2 text-w-blue-2">
                      Area Sales
                    </label>
                    <div
                      class="form-control py-1 px-3 text-gray-100 border-w-white-2 border before:border-b-2 before:border-w-white-2"
                    >
                      <span>-</span>
                    </div>
                  </div>
                </div>
                <div class="w-full">
                  <div class="form-group">
                    <label class="form-label bg-w-white-2 text-w-blue-2">
                      Location
                    </label>
                    <div
                      class="form-control py-1 px-3 text-gray-100 border-w-white-2 border before:border-b-2 before:border-w-white-2"
                    >
                      <span>-</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </line-thin>
        </div>

        <div class="grid sm:grid-cols-3 gap-3">
          <line-thin class="sm:col-span-1">
            <div class="z-10 relative p-4">
              <div class="grid gap-3">
                <div class="w-full">
                  <div class="form-group">
                    <label class="form-label bg-w-white-2 text-w-blue-2">
                      Group
                    </label>
                    <div
                      class="form-control py-1.5 px-3 text-gray-100 border-w-white-2 border before:border-b-2 before:border-w-white-2"
                    >
                      {{
                        customer.customer_group
                          ? customer.customer_group.name
                          : "-"
                      }}
                    </div>
                  </div>
                </div>
                <div class="w-full">
                  <div class="form-group">
                    <label class="form-label bg-w-white-2 text-w-blue-2">
                      Type
                    </label>
                    <div
                      class="form-control py-1.5 px-3 text-gray-100 border-w-white-2 border before:border-b-2 before:border-w-white-2"
                    >
                      {{
                        customer.customer_type
                          ? customer.customer_type.name
                          : "-"
                      }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </line-thin>

          <line-thin class="sm:col-span-2">
            <div class="gap-4 p-4 grid sm:grid-cols-7 relative z-1">
              <customer-map
                class="rounded invert sm:col-span-3"
                :customer.sync="customer"
              />

              <div class="space-y-1 sm:col-span-4">
                <div class="w-full">
                  <div class="form-group">
                    <label class="form-label bg-w-white-2 text-w-blue-2">
                      Address
                    </label>
                    <div
                      class="form-control py-1.5 px-3 text-gray-100 border-w-white-2 border before:border-b-2 before:border-w-white-2"
                    >
                      {{ customer.address }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </line-thin>
        </div>

        <div>
          <line-thin>
            <div class="relative border-b border-w-white-2">
              <div
                class="bg-white absolute top-0 left-0 w-full h-full opacity-10"
              ></div>
              <div class="text-white font-semibold z-10 relative px-4 py-2">
                Instrument Items
              </div>
            </div>
            <div class="z-10 relative space-y-4 p-4">
              <div class="grid sm:grid-cols-3 gap-3">
                <div
                  class="cyber-card relative p-4 pl-5"
                  data-augmented-ui
                  v-for="(item, idx) in customer.instrument_items"
                  :key="idx"
                >
                  <div class="flex justify-between space-x-2">
                    <div class="text-gray-300 font-semibold text-xl">
                      {{ item.instrument.brand_name }}
                    </div>
                    <div class="text-gray-300 font-semibold text-xl">
                      {{ item.instrument.name }}
                    </div>
                  </div>

                  <div class="grid pb-7 pt-3">
                    <div class="text-gray-300">
                      <div class="text-gray-400 leading-none">
                        Serial Number:
                      </div>
                      <div class="font-semibold leading-tight">
                        {{ item.serial_number }}
                      </div>
                    </div>
                    <div class="text-gray-300 py-2">
                      <div class="text-gray-400 leading-none">
                        Contract Type:
                      </div>
                      <div class="font-semibold leading-tight">
                        {{ item.contract_type.name }}
                      </div>
                    </div>
                    <div class="text-gray-300">
                      <div class="text-gray-400 leading-none">Segment:</div>
                      <div class="font-semibold leading-tight">
                        {{ item.instrument.segment_name }}
                      </div>
                    </div>
                  </div>

                  <div class="under-text-detail"></div>
                </div>
              </div>
              <div
                v-if="!customer.instrument_items"
                class="flex justify-center"
              >
                <div
                  class="relative z-10 glitch-effect text-xl font-Orbitron font-bold tracking-wide text-w-cyber-1 opacity-90"
                  title="I didn't find anything for you"
                >
                  I didn't find anything for you
                </div>
              </div>
            </div>
          </line-thin>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import LineThin from "@/components/LineThin";
import { mapActions, mapState } from "vuex";

import CustomerMap from "@/components/map/MapShow";

export default {
  name: "CustomerShow",
  components: {
    LineThin,
    CustomerMap,
  },
  data() {
    return {
      loading: true,
      location: {
        address: "",
        position: {
          lat: -7.7831651,
          lng: 110.3696965,
        },
      },
    };
  },
  computed: {
    ...mapState({
      customer: (state) => state.customers.customer,
    }),
  },
  watch: {
    customer: {
      handler(val) {
        this.setMap(val);
      },
      depe: true,
      immediate: true,
    },
    "$route.params": {
      handler(val) {
        this.setCustomer(val);
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    ...mapActions("customers", ["CUSTOMER_EDIT", "CUSTOMER_SHOW"]),
    async setCustomer(val) {
      this.loading = true;
      await this.CUSTOMER_SHOW(val.number);

      setTimeout(() => (this.loading = false), 500);
    },
    setMap(customer) {
      this.location = {
        address: customer.address,
        position: {
          lat: customer.latitude,
          lng: customer.longitude,
        },
      };
    },
  },
};
</script>
