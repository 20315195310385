import Vue from "vue";
import Vuex from "vuex";
import VuexPersist from "vuex-persist";

import auth from "./auth";
import menu from "./menu";
import customers from "./customers";
import areas from "./areas";
import masters from "./masters";

Vue.use(Vuex);

const vuexLocalStorage = new VuexPersist({
  key: "web",
  storage: window.localStorage,
  reducer: (state) => ({
    auth: state.auth,
    menu: state.menu,
    customers: state.customers,
    areas: state.areas,
    masters: state.masters,
  }),
});

const store = new Vuex.Store({
  modules: {
    auth,
    menu,
    customers,
    areas,
    masters,
  },
  plugins: [vuexLocalStorage.plugin],
});

export default store;
