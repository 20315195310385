export default function () {
  return {
    sidebar: false,
    backdrop: false,
    prompt: {},
    promptBackdrop: true,
    notifications: [],
    sideCustomer: false,
    sideArea: false,
    sideMaster: false,
  };
}
