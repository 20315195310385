import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./style/app.css";
import VueTypedJs from "vue-typed-js";
import vueDebounce from "vue-debounce";

Vue.use(vueDebounce);
Vue.use(VueTypedJs);
Vue.use(require("vue-moment"));

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
