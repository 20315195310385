export const listGroups = (state) => {
  let data = state.listGroups;

  let result = data.map((elm) => ({ id: elm.id, label: elm.name }));

  return result;
};

export const listTypes = (state) => {
  let data = state.listTypes;

  let result = data.map((elm) => ({ id: elm.id, label: elm.name }));

  return result;
};
