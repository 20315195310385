<template>
  <div class="lockscreen">
    <canvas id="mycanvas" width="100%" height="100%"></canvas>
  </div>
</template>

<script>
export default {
  name: "LockScreen",
  data() {
    return {
      step: 0,
      depth: 16,
      branchWidth: 12,
    };
  },
  created() {
    window.addEventListener("resize", this.reGrow);
  },
  destroyed() {
    window.removeEventListener("resize", this.reGrow);
  },
  mounted() {
    this.goDraw();
  },
  methods: {
    goDraw() {
      let canvas = document.getElementById("mycanvas");
      let ctx = canvas.getContext("2d");

      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
      ctx.globalCompositeOperation = "lighter";
      ctx.clearRect(0, 0, window.innerWidth, window.innerHeight);

      this.drawTree(
        ctx,
        ~~(window.innerWidth / 2),
        ~~(window.innerHeight / 1.02),
        98,
        -Math.PI / 2,
        this.depth,
        this.branchWidth
      );
    },
    drawTree(ctx, startX, startY, length, angle, depth, branchWidth) {
      let rand = Math.random,
        newDepth,
        newLength,
        newAngle,
        maxBranch = 3,
        endX,
        endY,
        maxAngle = (2 * Math.PI) / 4,
        subBranches;

      ctx.beginPath();
      ctx.moveTo(startX, startY);
      endX = startX + length * Math.cos(angle);
      endY = startY + length * Math.sin(angle);
      ctx.lineCap = "round";
      ctx.lineWidth = branchWidth;
      ctx.lineTo(endX, endY);

      if (depth <= 2) {
        ctx.strokeStyle = "rgb(0," + ((rand() * 64 + 128) >> 0) + ",0)";
      } else {
        ctx.strokeStyle = "rgb(" + ((rand() * 64 + 64) >> 0) + ",50, 25)";
      }
      ctx.stroke();

      newDepth = depth - 1;
      if (!newDepth) return;

      subBranches = maxBranch - 1;
      branchWidth *= 0.7;

      for (var i = 0; i < subBranches; i++) {
        newAngle = angle + rand() * maxAngle - maxAngle * 0.5;
        newLength = length * (0.7 + rand() * 0.3);
        setTimeout(() => {
          this.drawTree(
            ctx,
            endX,
            endY,
            newLength,
            newAngle,
            newDepth,
            branchWidth
          );
          this.step++;
        }, 100);
      }
    },
    reGrow() {
      if (this.step < 72000) return;

      this.goDraw();
    },
  },
};
</script>
