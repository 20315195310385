import ApiRequest from "../../services/apiRequest";

export const CUSTOMER_INDEX = async (context, params) => {
  return await ApiRequest.customerIndex(params)
    .then((response) => {
      context.commit("CUSTOMER_INDEX", response.data.data);
      return response.data.meta;
    })
    .catch((error) => {
      if (error) {
        // console.log(error)
      }
      return false;
    });
};

export const CUSTOMER_CREATE = async (context, data) => {
  return await ApiRequest.customerCreate(data)
    .then((response) => {
      context.dispatch("menu/RESPONSE_NOTIFICATIONS", response.data, {
        root: true,
      });
      return true;
    })
    .catch((error) => {
      if (error) {
        context.dispatch("menu/RESPONSE_NOTIFICATIONS", error.response.data, {
          root: true,
        });
        return false;
      }
    });
};

export const CUSTOMER_EDIT = async (context, data) => {
  return await ApiRequest.customerEdit(data)
    .then((response) => {
      context.dispatch("menu/RESPONSE_NOTIFICATIONS", response.data, {
        root: true,
      });
      return true;
    })
    .catch((error) => {
      if (error) {
        context.dispatch("menu/RESPONSE_NOTIFICATIONS", error.response.data, {
          root: true,
        });
        return false;
      }
    });
};

export const CUSTOMER_DELETE = async (context, data) => {
  return await ApiRequest.customerDelete(data)
    .then((response) => {
      context.dispatch("menu/RESPONSE_NOTIFICATIONS", response.data, {
        root: true,
      });
      return true;
    })
    .catch((error) => {
      if (error) {
        context.dispatch("menu/RESPONSE_NOTIFICATIONS", error.response.data, {
          root: true,
        });
        return false;
      }
    });
};

export const CUSTOMER_SHOW = async (context, data) => {
  return await ApiRequest.customerShow(data)
    .then((response) => {
      context.commit("CUSTOMER_SHOW", response.data.data);
      return true;
    })
    .catch((error) => {
      if (error) {
        // console.log(error)
      }
      return false;
    });
};

export const CUSTOMER_FORM = async (context, data) => {
  let form = {
    number: "",
    name: "",
    phone_number: null,
    latitude: null,
    longitude: null,
    address: null,
    area_support_id: null,
    area_sales_id: null,
    area_location_id: null,
    customer_type_id: null,
    customer_group_id: null,
  };

  if (data) form = data;

  await context.commit("CUSTOMER_FORM", form);
};

export const CUSTOMER_GROUP_LIST = async (context) => {
  return await ApiRequest.customerGroupIndex({
    per_page: 100,
  })
    .then((response) => {
      context.commit("CUSTOMER_GROUP_LIST", response.data.data);
      return response.data.data;
    })
    .catch((error) => {
      if (error) {
        // console.log(error)
      }
      return false;
    });
};

export const CUSTOMER_GROUP_INDEX = async (context, params) => {
  return await ApiRequest.customerGroupIndex(params)
    .then((response) => {
      context.commit("CUSTOMER_GROUP_INDEX", response.data.data);
      return response.data.meta;
    })
    .catch((error) => {
      if (error) {
        // console.log(error)
      }
      return false;
    });
};

export const CUSTOMER_GROUP_CREATE = async (context, data) => {
  return await ApiRequest.customerGroupCreate(data)
    .then((response) => {
      context.dispatch("menu/RESPONSE_NOTIFICATIONS", response.data, {
        root: true,
      });
      return true;
    })
    .catch((error) => {
      if (error) {
        context.dispatch("menu/RESPONSE_NOTIFICATIONS", error.response.data, {
          root: true,
        });
        return false;
      }
    });
};

export const CUSTOMER_GROUP_EDIT = async (context, data) => {
  return await ApiRequest.customerGroupEdit(data)
    .then((response) => {
      context.dispatch("menu/RESPONSE_NOTIFICATIONS", response.data, {
        root: true,
      });
      return true;
    })
    .catch((error) => {
      if (error) {
        context.dispatch("menu/RESPONSE_NOTIFICATIONS", error.response.data, {
          root: true,
        });
        return false;
      }
    });
};

export const CUSTOMER_GROUP_DELETE = async (context, data) => {
  return await ApiRequest.customerGroupDelete(data)
    .then((response) => {
      context.dispatch("menu/RESPONSE_NOTIFICATIONS", response.data, {
        root: true,
      });
      return true;
    })
    .catch((error) => {
      if (error) {
        context.dispatch("menu/RESPONSE_NOTIFICATIONS", error.response.data, {
          root: true,
        });
        return false;
      }
    });
};

export const CUSTOMER_TYPE_LIST = async (context) => {
  return await ApiRequest.customerTypeIndex({
    per_page: 100,
  })
    .then((response) => {
      context.commit("CUSTOMER_TYPE_LIST", response.data.data);
      return response.data.data;
    })
    .catch((error) => {
      if (error) {
        // console.log(error)
      }
      return false;
    });
};

export const CUSTOMER_TYPE_INDEX = async (context, params) => {
  return await ApiRequest.customerTypeIndex(params)
    .then((response) => {
      context.commit("CUSTOMER_TYPE_INDEX", response.data.data);
      return response.data.meta;
    })
    .catch((error) => {
      if (error) {
        // console.log(error)
      }
      return false;
    });
};

export const CUSTOMER_TYPE_CREATE = async (context, data) => {
  return await ApiRequest.customerTypeCreate(data)
    .then((response) => {
      context.dispatch("menu/RESPONSE_NOTIFICATIONS", response.data, {
        root: true,
      });
      return true;
    })
    .catch((error) => {
      if (error) {
        context.dispatch("menu/RESPONSE_NOTIFICATIONS", error.response.data, {
          root: true,
        });
        return false;
      }
    });
};

export const CUSTOMER_TYPE_EDIT = async (context, data) => {
  return await ApiRequest.customerTypeEdit(data)
    .then((response) => {
      context.dispatch("menu/RESPONSE_NOTIFICATIONS", response.data, {
        root: true,
      });
      return true;
    })
    .catch((error) => {
      if (error) {
        context.dispatch("menu/RESPONSE_NOTIFICATIONS", error.response.data, {
          root: true,
        });
        return false;
      }
    });
};

export const CUSTOMER_TYPE_DELETE = async (context, data) => {
  return await ApiRequest.customerTypeDelete(data)
    .then((response) => {
      context.dispatch("menu/RESPONSE_NOTIFICATIONS", response.data, {
        root: true,
      });
      return true;
    })
    .catch((error) => {
      if (error) {
        context.dispatch("menu/RESPONSE_NOTIFICATIONS", error.response.data, {
          root: true,
        });
        return false;
      }
    });
};
