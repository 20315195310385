<template>
  <div class="z-10 relative">
    <l-map
      ref="map"
      style="height: 300px; width: 100%; z-index: 20"
      class="rounded opacity-2"
      @dblclick="onMapClick"
      :zoom.sync="zoom"
      :center="[
        position.lat || defaultLocation.lat,
        position.lng || defaultLocation.lng,
      ]"
    >
      <l-tile-layer
        :url="tileProvider.url"
        :attribution="tileProvider.attribution"
      />
      <l-geosearch :options="geoSearchOptions"></l-geosearch>
      <l-marker
        ref="marker"
        draggable
        class="inver-0"
        :lat-lng.sync="position"
        @dragstart="dragging = true"
        @dragend="dragging = false"
        :icon="icon"
      >
      </l-marker>
    </l-map>
  </div>
</template>

<script>
import { LMap, LMarker, LTileLayer } from "vue2-leaflet";
import { OpenStreetMapProvider } from "leaflet-geosearch";
import LGeosearch from "vue2-leaflet-geosearch";
import { icon } from "leaflet";

import MarkerIcon from "@/assets/images/marker-icon.png";
import "leaflet/dist/leaflet.css";

export default {
  name: "CustomerMap",
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LGeosearch,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    customer: {
      type: Object,
    },
  },
  data() {
    return {
      loading: false,
      geoSearchOptions: {
        provider: new OpenStreetMapProvider(),
        showMarker: false,
        autoClose: true,
      },
      userLocation: {},
      icon: icon({
        iconRetinaUrl: MarkerIcon,
        iconUrl: MarkerIcon,
      }),

      position: {
        lat: -7.801283349503248,
        lng: 110.3647524815753,
      },
      address: "",
      tileProvider: {
        attribution:
          '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> &copy; <a href="https://carto.com/attributions">CARTO</a>',
        url: "https://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png",
      },
      zoom: 17,
      dragging: false,
    };
  },
  watch: {
    position: {
      deep: true,
      async handler(value) {
        this.address = await this.getAddress();
        this.$emit("input", { position: value, address: this.address });
      },
    },
    customer: {
      deep: true,
      handler(val) {
        this.setDefault(val);
      },
      immediate: true,
    },
  },
  // mounted() {
  //   this.setDefault();
  // },
  methods: {
    async getAddress() {
      this.loading = true;
      let address =
        this.customer && this.customer.address
          ? this.customer.address
          : "Unresolved address";

      try {
        const { lat, lng } = this.position;

        const result = await fetch(
          `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${lat}&lon=${lng}`
        );

        if (result.status === 200) {
          const body = await result.json();

          if (this.customer.lat != this.position.lat)
            address = body.display_name;
        }
      } catch (e) {
        console.error("Reverse Geocode Error->", e);
      }
      this.loading = false;
      return address;
    },
    async onMapClick(value) {
      // place the marker on the clicked spot
      this.position = value.latlng;
    },
    onSearch(value) {
      const loc = value.location;
      this.position = { lat: loc.y, lng: loc.x };
    },
    setDefault(customer) {
      if (customer && customer.number) {
        this.position = {
          lat: customer.latitude,
          lng: customer.longitude,
        };

        this.address = customer.address;
      } else {
        this.position = {
          lat: -7.801283349503248,
          lng: 110.3647524815753,
        };

        this.address = "";
      }

      if (this.$refs.map)
        this.$refs.map.mapObject.on("geosearch/showlocation", this.onSearch);
    },
  },
};
</script>
