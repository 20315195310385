<template>
  <div class="relative min-h-screen w-full overflow-hidden">
    <div class="error">
      <div class="wrap">
        <div class="404">
          <pre>
            <code>
              <span class="green">&lt;!</span><span>DOCTYPE html</span><span class="green">&gt;</span>
              <span class="orange">&lt;html&gt;</span>
              <span class="orange">&lt;style&gt;</span>
                * {
                  <span class="green">everything</span>:<span class="blue">awesome</span>;
                }
              <span class="orange">&lt;/style&gt;</span>
              <span class="orange">&lt;body&gt;</span> 
                  ERROR 404!
                      FILE NOT FOUND!
              <span class="comment">&lt;!--The file you are looking for, 
                  is not where you think it is.--&gt;
              </span>
              <span class="orange"></span> 
              <br />
              <br />
              <span class="orange">&nbsp;&lt;/body&gt;</span>
              <span class="orange">&lt;/html&gt;</span>
              <span class="info"></span>
            </code>
          </pre>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ErrorView",
};
</script>
