<template>
  <div>
    <div class="fixed max-w-sm right-3 top-16 z-50" v-if="notifications.length">
      <div
        class="flex my-3 justify-end"
        v-for="(notif, idx) in notifications.slice(0, 3)"
        :key="idx"
      >
        <div :class="notif ? notif.notifClass : ''">
          <div
            class="notif_content"
            data-augmented-ui="tl-clip br-clip"
            :class="notif ? notif.notifContent : ''"
          >
            <div
              class="notif_title text-black font-Orbitron text-sm font-semibold tracking-widest"
              data-augmented-ui="tl-clip br-clip inlay"
              :class="notif ? notif.notifTitle : ''"
            >
              {{ notif ? notif.title : "" }}
            </div>
            <div
              class="notif_description pl-4 pr-2.5 pt-1 pb-0.5"
              v-html="notif ? notif.description : ''"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  name: "BaseNotification",
  computed: {
    ...mapState({
      notifications: (state) => state.menu.notifications,
    }),
  },
  watch: {
    notifications: {
      handler(val) {
        if (val.length > 0) {
          this.removeNotif();
        }
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions("menu", ["TOGGLE_PROMPT", "REMOVE_NOTIFICATIONS"]),
    removeNotif() {
      setTimeout(() => {
        this.REMOVE_NOTIFICATIONS();
      }, 3000);
    },
  },
};
</script>
