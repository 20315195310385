var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"pagination space-x-1 flex"},[_c('li',{staticClass:"hidden sm:inline-block"},[_c('button',{staticClass:"text-w-white-4 text-sm px-2.5 py-1 cyber-br-sm",class:_vm.isInFirstPage
          ? ''
          : 'hover:bg-w-cyber-blue-2 hover:text-w-cyber-1 hover:backdrop-blur-sm',attrs:{"type":"button","disabled":_vm.isInFirstPage,"data-augmented-ui":"br-clip"},on:{"click":_vm.onClickFirstPage}},[_vm._v(" First ")])]),_c('li',[_c('button',{staticClass:"text-w-white-4 text-sm px-2.5 py-1 cyber-br-sm",class:_vm.isInFirstPage
          ? ''
          : 'hover:bg-w-cyber-blue-2 hover:text-w-cyber-1 hover:backdrop-blur-sm',attrs:{"type":"button","disabled":_vm.isInFirstPage,"data-augmented-ui":"br-clip"},on:{"click":_vm.onClickPreviousPage}},[_vm._v(" Previous ")])]),_vm._l((_vm.pages),function(page,index){return _c('li',{key:index,staticClass:"hidden sm:inline-block"},[_c('button',{staticClass:"text-w-white-4 cyber-br-sm h-8 w-8",class:_vm.isPageActive(page.name)
          ? 'bg-w-cyber-blue-2 text-w-cyber-blue-1 backdrop-blur-sm font-semibold'
          : 'hover:bg-w-cyber-blue-2 hover:text-w-cyber-1 hover:backdrop-blur-sm',attrs:{"type":"button","disabled":page.isDisabled,"data-augmented-ui":"br-clip"},on:{"click":function($event){return _vm.onClickPage(page.name)}}},[_vm._v(" "+_vm._s(page.name)+" ")])])}),_c('li',[_c('button',{staticClass:"text-w-white-4 text-sm px-2.5 py-1 cyber-br-sm",class:_vm.isInLastPage
          ? ''
          : 'hover:bg-w-cyber-blue-2 hover:text-w-cyber-1 hover:backdrop-blur-sm',attrs:{"type":"button","disabled":_vm.isInLastPage,"aria-label":"Go to next page","data-augmented-ui":"br-clip"},on:{"click":_vm.onClickNextPage}},[_vm._v(" Next ")])]),_c('li',{staticClass:"hidden sm:inline-block"},[_c('button',{staticClass:"text-w-white-4 text-sm px-2.5 py-1 cyber-br-sm",class:_vm.isInLastPage
          ? ''
          : 'hover:bg-w-cyber-blue-2 hover:text-w-cyber-1 hover:backdrop-blur-sm',attrs:{"type":"button","disabled":_vm.isInLastPage,"aria-label":"Go to last page","data-augmented-ui":"br-clip"},on:{"click":_vm.onClickLastPage}},[_vm._v(" Last ")])])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }