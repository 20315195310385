import ApiRequest from "../../services/apiRequest";
import TokenService from "../../services/tokenService";

export const ME = async (context) => {
  return await ApiRequest.me()
    .then((response) => {
      context.commit("ME", response.data.data);
      return true;
    })
    .catch((error) => {
      if (error) {
        // console.log(error)
      }
      return false;
    });
};

export const LOGOUT = async (context) => {
  return await ApiRequest.logout()
    .then((res) => {
      if (res.data.status_code == 200) {
        context.commit("LOGOUT");
        TokenService.removeToken();
        return true;
      }
      return false;
    })
    .catch((error) => {
      if (error) {
        // console.log(error)
      }
      return false;
    });
};

export const LOGIN = async (context, val) => {
  return await ApiRequest.login(val)
    .then((response) => {
      TokenService.setAccessToken(response.data.data.access_token);
      context.dispatch("ME");
      return true;
    })
    .catch((error) => {
      if (error) {
        return error.response.data.message;
      }
      // return false;
    });
};
