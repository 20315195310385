export const AREA_SUPPORT_LIST = (state, data) => {
  state.listSupports = data;
};

export const AREA_SUPPORT_INDEX = (state, data) => {
  state.supports = data;
};

export const AREA_COLLECTOR_LIST = (state, data) => {
  state.listCollectors = data;
};

export const AREA_COLLECTOR_INDEX = (state, data) => {
  state.collectors = data;
};

export const AREA_ASSET_LIST = (state, data) => {
  state.listAssets = data;
};

export const AREA_ASSET_INDEX = (state, data) => {
  state.assets = data;
};

export const AREA_SALES_LIST = (state, data) => {
  state.listSales = data;
};

export const AREA_SALES_INDEX = (state, data) => {
  state.sales = data;
};

export const AREA_PIVOT_INDEX = (state, data) => {
  state.pivots = data;
};
