var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"customers-add space-y-4"},[_c('div',{staticClass:"flex justify-between items-center pb-1"},[_c('div',{staticClass:"text-white font-semibold text-xl sm:text-3xl"},[_vm._v(" Edit "+_vm._s(_vm.$route.meta.title)+" ")]),_c('button',{staticClass:"cyber-btn-primary px-3.5 py-1.5 font-medium text-w-cyber-blue-1 hover:bg-w-cyber-blue-2 duration-100",attrs:{"data-augmented-ui":"br-clip"},on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v(" Back_ ")])]),_c('div',{staticClass:"grid gap-3"},[_c('line-thin',[_c('div',{staticClass:"z-5 relative p-4"},[_c('div',{staticClass:"grid pb-2"},[_c('div',{staticClass:"w-full"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"form-label bg-w-white-2 text-w-blue-2",class:{
                  'bg-w-cyber-red-1 text-w-cyber-red-2': _vm.v$.form.name.$error,
                }},[_vm._v(" Name ")]),_c('div',{staticClass:"form-control",class:_vm.v$.form.name.$error
                    ? 'border-w-cyber-red-1 border before:border-b-2 before:border-w-cyber-red-1'
                    : 'border-w-white-2 border before:border-b-2 before:border-w-white-2'},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.name),expression:"form.name"}],staticClass:"form-control px-3 py-2 text-white text-sm placeholder-w-white-2",class:{
                    'text-w-cyber-red-1': _vm.v$.form.name.$error,
                  },attrs:{"placeholder":"Customer Type Name"},domProps:{"value":(_vm.form.name)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "name", $event.target.value)}}})])]),(_vm.v$.form.name.$error)?_c('div',{staticClass:"text-red-500 font-semibold text-xs capitalize mt-1"},[_vm._v(" "+_vm._s(`${_vm.v$.form.name.$errors[0].$property} ${_vm.v$.form.name.$errors[0].$message}`)+" ")]):_vm._e()])])])])],1),_c('div',{staticClass:"flex justify-end pb-6 pt-2"},[_c('button',{staticClass:"cybr-btn group pr-3 pl-4 py-1 tracking-widest z-1",attrs:{"type":"submit","disabled":_vm.conn},on:{"click":_vm.toSave}},[_vm._m(0),_c('span',{staticClass:"cybr-btn__glitch font-Orbitron",attrs:{"aria-hidden":""}},[_vm._v("Create_")]),_c('span',{staticClass:"cybr-btn__tag font-Orbitron",attrs:{"aria-hidden":""}},[_vm._v("R25")])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"font-Orbitron"},[_vm._v("Save"),_c('span',{attrs:{"aria-hidden":""}},[_vm._v("_")])])
}]

export { render, staticRenderFns }