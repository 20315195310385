<template>
  <div class="z-10 relative">
    <l-map
      style="height: 300px; width: 100%; z-index: 20"
      class="rounded opacity-2"
      ref="map"
      :zoom="zoom"
      :center="[
        position.lat || defaultLocation.lat,
        position.lng || defaultLocation.lng,
      ]"
      :options="{ scrollWheelZoom: false }"
    >
      <l-tile-layer
        :url="tileProvider.url"
        :attribution="tileProvider.attribution"
      />
      <l-marker ref="marker" visible :lat-lng.sync="position" :icon="iconCus()">
      </l-marker>
    </l-map>
  </div>
</template>

<script>
import { LMap, LMarker, LTileLayer } from "vue2-leaflet";
import L from "leaflet";

export default {
  name: "ThumbnailMap",
  components: {
    LMap,
    LTileLayer,
    LMarker,
  },
  props: {
    customer: {
      type: Object,
    },
    defaultLocation: {
      type: Object,
      default: () => ({
        lat: -7.801283349503248,
        lng: 110.3647524815753,
      }),
    },
  },
  data() {
    return {
      position: {
        lat: -7.801283349503248,
        lng: 110.3647524815753,
      },
      address: "",
      tileProvider: {
        attribution:
          '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> &copy; <a href="https://carto.com/attributions">CARTO</a>',
        url: "https://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png",
      },
      zoom: 17,
      dragging: false,
    };
  },

  watch: {
    customer: {
      deep: true,
      immediate: true,
      handler() {
        this.setDefault();
      },
    },
  },
  methods: {
    setDefault() {
      let loc = this.customer;

      if (loc.latitude) {
        this.position = {
          lat: loc.latitude,
          lng: loc.longitude,
        };

        this.address = loc.address;
      }
    },
    iconCus() {
      let redIcon = new L.divIcon({
        html:
          '<div class="h-6 w-6">' +
          `<svg id="_x31_-outline-expand" style="enable-background:new 0 0 64 64;" version="1.1" viewBox="0 0 64 64" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><path d="M47,21V2H17v19H2v41h26V50h-3v-2h14v2h-3v12h26V21H47z M13,51H7v-6h6V51z M13,41H7v-6h6V41z M13,31H7  v-6h6V31z M25,43h-4v-4h4V43z M25,35h-4v-4h4V35z M25,27h-4v-4h4V27z M39,23h4v4h-4V23z M39,31h4v4h-4V31z M34,43h-4v-4h4V43z   M34,35h-4v-4h4V35z M34,27h-4v-4h4V27z M38,15h-4v4h-4v-4h-4v-4h4V7h4v4h4V15z M39,43v-4h4v4H39z M44,58h-2v-2h2V58z M44,54h-2v-2  h2V54z M44,50h-2v-2h2V50z M57,51h-6v-6h6V51z M57,41h-6v-6h6V41z M57,31h-6v-6h6V31z" style="fill:#3A4249;"/></svg>` +
          "</div>",
        iconSize: [100, 2],
        iconAnchor: [0, 0],
        className: "myDivIcon",
      });

      return redIcon;
    },
  },
};
</script>
