import api from "./api";

class ApiRequest {
  // auth
  login(data) {
    return api.post("/api/signin", data);
  }
  me() {
    return api.get("/api/me");
  }
  logout() {
    return api.get("/api/signout");
  }
  // customers
  customerIndex(params) {
    return api.get("/api/cms/customers", { params: params });
  }
  customerCreate(data) {
    return api.post("/api/cms/customers", data);
  }
  customerEdit(data) {
    return api.put(`/api/cms/customers/${data.number}`, data);
  }
  customerDelete(number) {
    return api.delete(`/api/cms/customers/${number}`);
  }
  customerShow(number) {
    return api.get(`/api/cms/customers/${number}`);
  }
  // customer groups
  customerGroupIndex(params) {
    return api.get("/api/cms/customer-groups", { params: params });
  }
  customerGroupCreate(data) {
    return api.post("/api/cms/customer-groups", data);
  }
  customerGroupEdit(data) {
    return api.put(`/api/cms/customer-groups/${data.id}`, data);
  }
  customerGroupDelete(id) {
    return api.delete(`/api/cms/customer-groups/${id}`);
  }
  // customer types
  customerTypeIndex(params) {
    return api.get("/api/cms/customer-types", { params: params });
  }
  customerTypeCreate(data) {
    return api.post("/api/cms/customer-types", data);
  }
  customerTypeEdit(data) {
    return api.put(`/api/cms/customer-types/${data.id}`, data);
  }
  customerTypeDelete(id) {
    return api.delete(`/api/cms/customer-types/${id}`);
  }
  // area support
  areaSupportIndex(params) {
    return api.get("/api/cms/area-supports", { params: params });
  }
  areaSupportCreate(data) {
    return api.post("/api/cms/area-supports", data);
  }
  areaSupportEdit(data) {
    return api.put(`/api/cms/area-supports/${data.id}`, data);
  }
  areaSupportDelete(id) {
    return api.delete(`/api/cms/area-supports/${id}`);
  }

  // area sales
  areaSalesIndex(params) {
    return api.get("/api/cms/area-sales", { params: params });
  }
  areaSalesCreate(data) {
    return api.post("/api/cms/area-sales", data);
  }
  areaSalesEdit(data) {
    return api.put(`/api/cms/area-sales/${data.id}`, data);
  }
  areaSalesDelete(id) {
    return api.delete(`/api/cms/area-sales/${id}`);
  }

  // area collector
  areaCollectorIndex(params) {
    return api.get("/api/cms/area-collectors", { params: params });
  }
  areaCollectorCreate(data) {
    return api.post("/api/cms/area-collectors", data);
  }
  areaCollectorEdit(data) {
    return api.put(`/api/cms/area-collectors/${data.id}`, data);
  }
  areaCollectorDelete(id) {
    return api.delete(`/api/cms/area-collectors/${id}`);
  }

  // area asset
  areaAssetIndex(params) {
    return api.get("/api/cms/area-assets", { params: params });
  }
  areaAssetCreate(data) {
    return api.post("/api/cms/area-assets", data);
  }
  areaAssetEdit(data) {
    return api.put(`/api/cms/area-assets/${data.id}`, data);
  }
  areaAssetDelete(id) {
    return api.delete(`/api/cms/area-assets/${id}`);
  }

  // area pivot
  areaPivotIndex(params) {
    return api.get("/api/cms/area-pivots", { params: params });
  }
  areaPivotCreate(data) {
    return api.post("/api/cms/area-pivots", data);
  }
  areaPivotEdit(data) {
    return api.put(`/api/cms/area-pivots/${data.id}`, data);
  }
  areaPivotDelete(id) {
    return api.delete(`/api/cms/area-pivots/${id}`);
  }

  // districts
  districtIndex(params) {
    return api.get("/api/cms/districts", { params: params });
  }

  // instruments
  instrumentIndex(params) {
    return api.get("/api/cms/instruments", { params: params });
  }
  instrumentCreate(data) {
    return api.post("/api/cms/instruments", data);
  }
  instrumentEdit(data) {
    return api.put(`/api/cms/instruments/${data.id}`, data);
  }
  instrumentDelete(id) {
    return api.delete(`/api/cms/instruments/${id}`);
  }
  brandIndex(params) {
    return api.get("/api/cms/brands", { params: params });
  }
  brandCreate(data) {
    return api.post("/api/cms/brands", data);
  }
  brandEdit(data) {
    return api.put(`/api/cms/brands/${data.id}`, data);
  }
  brandDelete(id) {
    return api.delete(`/api/cms/brands/${id}`);
  }
  segmentIndex(params) {
    return api.get("/api/cms/segments", { params: params });
  }
  segmentCreate(data) {
    return api.post("/api/cms/segments", data);
  }
  segmentEdit(data) {
    return api.put(`/api/cms/segments/${data.id}`, data);
  }
  segmentDelete(id) {
    return api.delete(`/api/cms/segments/${id}`);
  }
}

export default new ApiRequest();
