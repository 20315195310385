import ApiRequest from "../../services/apiRequest";

export const INSTRUMENT_INDEX = async (context, params) => {
  return await ApiRequest.instrumentIndex(params)
    .then((response) => {
      context.commit("INSTRUMENT_INDEX", response.data.data);
      return response.data.meta;
    })
    .catch((error) => {
      if (error) {
        // console.log(error)
      }
      return false;
    });
};

export const INSTRUMENT_CREATE = async (context, data) => {
  return await ApiRequest.instrumentCreate(data)
    .then((response) => {
      context.dispatch("menu/RESPONSE_NOTIFICATIONS", response.data, {
        root: true,
      });
      return true;
    })
    .catch((error) => {
      if (error) {
        context.dispatch("menu/RESPONSE_NOTIFICATIONS", error.response.data, {
          root: true,
        });
        return false;
      }
    });
};

export const INSTRUMENT_EDIT = async (context, data) => {
  return await ApiRequest.instrumentEdit(data)
    .then((response) => {
      context.dispatch("menu/RESPONSE_NOTIFICATIONS", response.data, {
        root: true,
      });
      return true;
    })
    .catch((error) => {
      if (error) {
        context.dispatch("menu/RESPONSE_NOTIFICATIONS", error.response.data, {
          root: true,
        });
        return false;
      }
    });
};

export const INSTRUMENT_DELETE = async (context, data) => {
  return await ApiRequest.instrumentDelete(data)
    .then((response) => {
      context.dispatch("menu/RESPONSE_NOTIFICATIONS", response.data, {
        root: true,
      });
      return true;
    })
    .catch((error) => {
      if (error) {
        context.dispatch("menu/RESPONSE_NOTIFICATIONS", error.response.data, {
          root: true,
        });
        return false;
      }
    });
};

export const BRAND_LISTS = async (context, params) => {
  return await ApiRequest.brandIndex(params)
    .then((response) => {
      context.commit("BRAND_LISTS", response.data.data);
      return true;
    })
    .catch((error) => {
      if (error) {
        // console.log(error)
      }
      return false;
    });
};

export const BRAND_INDEX = async (context, params) => {
  return await ApiRequest.brandIndex(params)
    .then((response) => {
      context.commit("BRAND_INDEX", response.data.data);
      return response.data.meta;
    })
    .catch((error) => {
      if (error) {
        // console.log(error)
      }
      return false;
    });
};

export const BRAND_CREATE = async (context, data) => {
  return await ApiRequest.brandCreate(data)
    .then((response) => {
      context.dispatch("menu/RESPONSE_NOTIFICATIONS", response.data, {
        root: true,
      });
      return true;
    })
    .catch((error) => {
      if (error) {
        context.dispatch("menu/RESPONSE_NOTIFICATIONS", error.response.data, {
          root: true,
        });
        return false;
      }
    });
};

export const BRAND_EDIT = async (context, data) => {
  return await ApiRequest.brandEdit(data)
    .then((response) => {
      context.dispatch("menu/RESPONSE_NOTIFICATIONS", response.data, {
        root: true,
      });
      return true;
    })
    .catch((error) => {
      if (error) {
        context.dispatch("menu/RESPONSE_NOTIFICATIONS", error.response.data, {
          root: true,
        });
        return false;
      }
    });
};

export const BRAND_DELETE = async (context, data) => {
  return await ApiRequest.brandDelete(data)
    .then((response) => {
      context.dispatch("menu/RESPONSE_NOTIFICATIONS", response.data, {
        root: true,
      });
      return true;
    })
    .catch((error) => {
      if (error) {
        context.dispatch("menu/RESPONSE_NOTIFICATIONS", error.response.data, {
          root: true,
        });
        return false;
      }
    });
};

export const SEGMENT_INDEX = async (context, params) => {
  return await ApiRequest.segmentIndex(params)
    .then((response) => {
      context.commit("SEGMENT_INDEX", response.data.data);
      return response.data.meta;
    })
    .catch((error) => {
      if (error) {
        // console.log(error)
      }
      return false;
    });
};

export const SEGMENT_CREATE = async (context, data) => {
  return await ApiRequest.segmentCreate(data)
    .then((response) => {
      context.dispatch("menu/RESPONSE_NOTIFICATIONS", response.data, {
        root: true,
      });
      return true;
    })
    .catch((error) => {
      if (error) {
        context.dispatch("menu/RESPONSE_NOTIFICATIONS", error.response.data, {
          root: true,
        });
        return false;
      }
    });
};

export const SEGMENT_EDIT = async (context, data) => {
  return await ApiRequest.segmentEdit(data)
    .then((response) => {
      context.dispatch("menu/RESPONSE_NOTIFICATIONS", response.data, {
        root: true,
      });
      return true;
    })
    .catch((error) => {
      if (error) {
        context.dispatch("menu/RESPONSE_NOTIFICATIONS", error.response.data, {
          root: true,
        });
        return false;
      }
    });
};

export const SEGMENT_DELETE = async (context, data) => {
  return await ApiRequest.segmentDelete(data)
    .then((response) => {
      context.dispatch("menu/RESPONSE_NOTIFICATIONS", response.data, {
        root: true,
      });
      return true;
    })
    .catch((error) => {
      if (error) {
        context.dispatch("menu/RESPONSE_NOTIFICATIONS", error.response.data, {
          root: true,
        });
        return false;
      }
    });
};
