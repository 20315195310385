<template>
  <div class="font-sans">
    <div
      class="container max-w-md mx-auto min-h-screen flex flex-wrap flex-1 justify-center content-center"
    >
      <div class="w-full max-w-sm">
        <line-normal class="leading-loose">
          <div class="w-full relative z-10">
            <div class="p-4">
              <div
                class="font-Orbitron uppercase text-white text-sm font-semibold tracking-widest"
              >
                input:&ensp;username&ensp;+&ensp;password
              </div>
            </div>
            <div class="px-4 pb-1">
              <div class="hr-h23"></div>
            </div>
            <div class="py-2 px-4">
              <div class="grid sm:grid-cols-2 gap-2 items-center">
                <div>
                  <input
                    class="w-full border-2 border-w-white-2 px-3 py-1 text-white rounded bg-transparent focus:outline-none z-1 relative focus:border-y-0"
                    autocomplete="off"
                    :class="
                      v$.form.email.$error
                        ? 'border-x border-y-0 font-semibold border-red-500 text-red-500 placeholder-red-500'
                        : ''
                    "
                    type="email"
                    placeholder="Email"
                    v-model="form.email"
                  />
                </div>
                <div>
                  <input
                    autocomplete="off"
                    class="outline-none w-full border-2 border-w-white-2 px-3 py-1 text-white rounded focus:outline-none bg-transparent focus:border-y-0 z-1"
                    :class="
                      v$.form.password.$error
                        ? 'font-semibold border-x border-y-0 border-red-500 text-red-500 placeholder-red-500'
                        : ''
                    "
                    type="password"
                    placeholder="Password"
                    v-model="form.password"
                  />
                </div>
              </div>
            </div>

            <div class="items-center flex relative px-4 pb-4 pt-1">
              <button
                class="cybr-btn group px-4 py-1 tracking-widest w-full z-1"
                type="submit"
                @click="onLogin"
                :disabled="conn"
              >
                <span class="font-Orbitron">
                  Connect<span aria-hidden>_</span>
                </span>
                <span aria-hidden class="cybr-btn__glitch font-Orbitron"
                  >Connect_</span
                >
                <span aria-hidden class="cybr-btn__tag font-Orbitron">R25</span>
              </button>
            </div>
          </div>
        </line-normal>
      </div>

      <div
        class="w-full max-w-sm mt-4"
        v-if="v$.form.email.$error || v$.form.password.$error"
      >
        <LineNormal class="leading-loose p-4">
          <div
            class="animate-pulse font-Orbitron uppercase text-white text-sm font-semibold tracking-widest"
          >
            INPUT:&ensp;
            <span class="text-red-500">
              ERROR#{{ Math.floor(Date.now() / 1000) }}
            </span>
          </div>
          <div class="hr-h23 my-1 animate-pulse"></div>
          <div class="grid px-4 py-1 bg-w-blue-1 animate-pulse rounded">
            <ul
              class="list-disc px-2"
              v-for="(err, idx) of v$.form.email.$errors"
              :key="'e' + idx"
            >
              <li class="text-red-500 text-sm capitalize">
                {{ `${err.$property} ${err.$message}` }}
              </li>
            </ul>

            <ul
              class="list-disc px-2"
              v-for="(err, id) of v$.form.password.$errors"
              :key="'p' + id"
            >
              <li class="text-red-500 text-sm capitalize">
                {{ `${err.$property} ${err.$message}` }}
              </li>
            </ul>
          </div>
        </LineNormal>
      </div>

      <div class="w-full max-w-sm mt-4" v-if="connecting">
        <line-normal class="leading-loose p-4 grid gap-1">
          <div class="uppercase tracking-widest justify-between flex">
            <div class="flex">
              <vue-typed-js
                :strings="['VERIFYING&ensp;CREDENTIALS']"
                :typeSpeed="30"
                :loop="false"
                :showCursor="false"
                @onComplete="dotvc = !dotvc"
              >
                <div class="opacity-60 typing text-white text-sm"></div>
              </vue-typed-js>
              <vue-typed-js
                v-if="dotvc && res.dotvc"
                :strings="['...']"
                :typeSpeed="35"
                :loop="true"
                :showCursor="false"
                @onComplete="stopDotVc"
              >
                <div class="opacity-60 typing text-white text-sm"></div>
              </vue-typed-js>
              <span
                v-if="res.req && !res.dotvc"
                class="opacity-60 text-white text-sm"
              >
                ...
              </span>
            </div>
            <div class="text-sm font-medium opacity-75" v-if="res.ok">
              <span v-if="res.req === true" class="text-green-500">OK</span>
              <span v-if="res.req !== true" class="text-red-500">FAIL</span>
            </div>
          </div>

          <div
            class="uppercase tracking-widest justify-between flex"
            v-if="res.req !== true && res.ok"
          >
            <div class="text-red-500 text-sm animate-pulse">
              {{ res.req }}
            </div>
          </div>

          <div
            class="uppercase tracking-widest justify-between flex"
            v-if="res.ok && res.req === true"
          >
            <div class="flex">
              <vue-typed-js
                :strings="['INITIALIZING&ensp;DATABASE...']"
                :typeSpeed="40"
                :loop="false"
                :showCursor="false"
                @onComplete="okd = !okd"
              >
                <div class="opacity-60 typing text-white text-sm"></div>
              </vue-typed-js>
            </div>
            <div class="text-green-500 opacity-75 text-sm" v-if="okd">OK</div>
          </div>

          <div
            class="uppercase tracking-widest justify-between flex"
            v-if="okd"
          >
            <div class="flex">
              <vue-typed-js
                :strings="['FINALIZING&ensp;UI...']"
                :typeSpeed="35"
                :loop="false"
                :showCursor="false"
                @onComplete="toHome"
              >
                <div
                  class="opacity-60 typing text-white text-sm font-medium"
                ></div>
              </vue-typed-js>
            </div>
          </div>
        </line-normal>
      </div>
    </div>
  </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";
import LineNormal from "@/components/LineNormal.vue";
import { mapActions } from "vuex";

export default {
  name: "LoginView",
  components: {
    LineNormal,
  },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      connecting: false,
      conn: false,
      res: {
        req: null,
        dotvc: true,
        ok: false,
      },
      dotvc: false,
      okd: false,
      dotVcDone: false,
      form: {
        email: "",
        password: "",
      },
    };
  },
  validations() {
    return {
      form: {
        email: {
          required,
          email,
        },
        password: {
          required,
        },
      },
    };
  },
  watch: {
    "res.dotvc": function (val) {
      if (!val) this.res.ok = true;
      else this.res.ok = false;
    },
  },
  methods: {
    ...mapActions("auth", ["LOGIN"]),
    async onLogin() {
      this.connecting = false;
      this.conn = true;

      this.res = {
        req: null,
        dotvc: true,
        ok: false,
      };
      this.dotvc = false;
      this.okd = false;
      this.dotVcDone = false;
      const result = await this.v$.$validate();
      if (!result) {
        this.conn = false;
        return;
      }

      this.connecting = true;
      let res = await this.LOGIN(this.form);

      if (res) {
        setTimeout(() => {
          this.res.req = res;
          if (res !== true) this.conn = false;
        }, 1500);
      }
    },
    stopDotVc() {
      setTimeout(() => (this.res.dotvc = false), 2000);
    },
    toHome() {
      if (this.res.req === true) this.$router.push({ name: "home" });
    },
  },
};
</script>
