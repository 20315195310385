<template>
  <div class="customers-add space-y-4">
    <div class="flex justify-between items-center pb-1">
      <div class="text-white font-semibold text-xl sm:text-3xl">
        Create {{ $route.meta.title }}
      </div>

      <button
        class="cyber-btn-primary px-3.5 py-1.5 font-medium text-w-cyber-blue-1 hover:bg-w-cyber-blue-2 duration-100"
        data-augmented-ui="br-clip"
        @click="$router.go(-1)"
      >
        Back_
      </button>
    </div>
    <div class="grid sm:grid-cols-2 gap-3">
      <line-thin>
        <div class="z-5 relative p-4">
          <div class="grid gap-3">
            <div class="w-full">
              <div class="form-group">
                <label
                  class="form-label bg-w-white-2 text-w-blue-2"
                  :class="{
                    'bg-w-cyber-red-1 text-w-cyber-red-2':
                      v$.form.number.$error,
                  }"
                >
                  Number
                </label>
                <div
                  class="form-control"
                  :class="
                    v$.form.number.$error
                      ? 'border-w-cyber-red-1 border before:border-b-2 before:border-w-cyber-red-1'
                      : 'border-w-white-2 border before:border-b-2 before:border-w-white-2'
                  "
                >
                  <input
                    class="form-control px-3 py-2 text-white text-sm placeholder-w-white-2"
                    placeholder="Customer Number"
                    v-model="form.number"
                    :class="{
                      'text-w-cyber-red-1': v$.form.number.$error,
                    }"
                  />
                </div>
              </div>
              <div
                v-if="v$.form.number.$error"
                class="text-red-500 font-semibold text-xs capitalize mt-1"
              >
                {{
                  `${v$.form.number.$errors[0].$property} ${v$.form.number.$errors[0].$message}`
                }}
              </div>
            </div>

            <div class="w-full">
              <div class="form-group">
                <label
                  class="form-label bg-w-white-2 text-w-blue-2"
                  :class="{
                    'bg-w-cyber-red-1 text-w-cyber-red-2': v$.form.name.$error,
                  }"
                >
                  Name
                </label>
                <div
                  class="form-control"
                  :class="
                    v$.form.name.$error
                      ? 'border-w-cyber-red-1 border before:border-b-2 before:border-w-cyber-red-1'
                      : 'border-w-white-2 border before:border-b-2 before:border-w-white-2'
                  "
                >
                  <input
                    class="form-control px-3 py-2 text-white text-sm placeholder-w-white-2"
                    placeholder="Customer Name"
                    v-model="form.name"
                    :class="{
                      'text-w-cyber-red-1': v$.form.name.$error,
                    }"
                  />
                </div>
              </div>
              <div
                v-if="v$.form.name.$error"
                class="text-red-500 font-semibold text-xs capitalize mt-1"
              >
                {{
                  `${v$.form.name.$errors[0].$property} ${v$.form.name.$errors[0].$message}`
                }}
              </div>
            </div>

            <div class="w-full">
              <div class="form-group">
                <label
                  class="form-label bg-w-white-2 text-w-blue-2"
                  :class="{
                    'bg-w-cyber-red-1 text-w-cyber-red-2':
                      v$.form.phone_number.$error,
                  }"
                >
                  Phone Number
                </label>
                <div
                  class="form-control"
                  :class="
                    v$.form.phone_number.$error
                      ? 'border-w-cyber-red-1 border before:border-b-2 before:border-w-cyber-red-1'
                      : 'border-w-white-2 border before:border-b-2 before:border-w-white-2'
                  "
                >
                  <input
                    class="form-control px-3 py-2 text-white text-sm placeholder-w-white-2"
                    placeholder="Customer Phone Number"
                    v-model="form.phone_number"
                    :class="{
                      'text-w-cyber-red-1': v$.form.phone_number.$error,
                    }"
                  />
                </div>
              </div>
              <div
                v-if="v$.form.phone_number.$error"
                class="text-red-500 font-semibold text-xs capitalize mt-1"
              >
                {{ `Phone Number ${v$.form.phone_number.$errors[0].$message}` }}
              </div>
            </div>
          </div>
        </div>
      </line-thin>
      <line-thin>
        <div class="relative border-b border-w-white-2">
          <div
            class="bg-white absolute top-0 left-0 w-full h-full opacity-10"
          ></div>
          <div class="text-white font-semibold z-10 relative px-4 py-2">
            Area
          </div>
        </div>
        <div class="z-5 relative space-y-4 p-4">
          <div class="grid gap-3">
            <div class="w-full">
              <div class="form-group">
                <label
                  class="form-label bg-w-white-2 text-w-blue-2"
                  :class="{
                    'bg-w-cyber-red-1 text-w-cyber-red-2':
                      v$.form.area_support_id.$error,
                  }"
                >
                  Area Support
                </label>
                <div
                  class="form-control h-9.5 absolute fc"
                  :class="
                    v$.form.area_support_id.$error
                      ? 'border-w-cyber-red-1 border before:border-b-2 before:border-w-cyber-red-1'
                      : 'border-w-white-2 border before:border-b-2 before:border-w-white-2'
                  "
                ></div>
                <treeselect
                  v-model="form.area_support_id"
                  :multiple="false"
                  :appendToBody="true"
                  placeholder="Select Area Support"
                  :options="listSupports"
                  class="relative z-5 text-white text-sm placeholder-w-white-2 mt-0.5"
                  :class="{
                    'text-w-cyber-red-1': v$.form.area_support_id.$error,
                  }"
                />
              </div>
              <div
                v-if="v$.form.area_support_id.$error"
                class="text-red-500 font-semibold text-xs capitalize"
              >
                {{
                  `Area Support ${v$.form.area_support_id.$errors[0].$message}`
                }}
              </div>
            </div>
            <div class="w-full">
              <div class="form-group">
                <label
                  class="form-label bg-w-white-2 text-w-blue-2"
                  :class="{
                    'bg-w-cyber-red-1 text-w-cyber-red-2':
                      v$.form.area_sales_id.$error,
                  }"
                >
                  Area Sales
                </label>
                <div
                  class="form-control h-9.5 absolute fc"
                  :class="
                    v$.form.area_sales_id.$error
                      ? 'border-w-cyber-red-1 border before:border-b-2 before:border-w-cyber-red-1'
                      : 'border-w-white-2 border before:border-b-2 before:border-w-white-2'
                  "
                ></div>
                <treeselect
                  v-model="form.area_sales_id"
                  :multiple="false"
                  :options="sales"
                  :appendToBody="true"
                  placeholder="Select Area Sales"
                  class="relative z-4 text-white text-sm placeholder-w-white-2 mt-0.5"
                  :class="{
                    'text-w-cyber-red-1': v$.form.area_sales_id.$error,
                  }"
                />
              </div>
              <div
                v-if="v$.form.area_sales_id.$error"
                class="text-red-500 font-semibold text-xs capitalize"
              >
                {{
                  `Area Support ${v$.form.area_sales_id.$errors[0].$message}`
                }}
              </div>
            </div>
            <div class="w-full">
              <div class="form-group">
                <label
                  class="form-label bg-w-white-2 text-w-blue-2"
                  :class="{
                    'bg-w-cyber-red-1 text-w-cyber-red-2':
                      v$.form.area_location_id.$error,
                  }"
                >
                  Location
                </label>
                <div
                  class="form-control h-9.5 absolute fc"
                  :class="
                    v$.form.area_location_id.$error
                      ? 'border-w-cyber-red-1 border before:border-b-2 before:border-w-cyber-red-1'
                      : 'border-w-white-2 border before:border-b-2 before:border-w-white-2'
                  "
                ></div>
                <treeselect
                  v-model="form.area_location_id"
                  :multiple="false"
                  :appendToBody="true"
                  placeholder="Select Location"
                  :options="locations"
                  class="relative z-3 text-white text-sm placeholder-w-white-2 mt-0.5"
                  :class="{
                    'text-w-cyber-red-1': v$.form.area_location_id.$error,
                  }"
                />
              </div>
              <div
                v-if="v$.form.area_location_id.$error"
                class="text-red-500 font-semibold text-xs capitalize"
              >
                {{ `Location ${v$.form.area_location_id.$errors[0].$message}` }}
              </div>
            </div>
          </div>
        </div>
      </line-thin>
    </div>

    <div class="grid sm:grid-cols-3 gap-3">
      <line-thin class="sm:col-span-1">
        <div class="z-5 relative space-y-4 p-4">
          <div class="grid gap-3">
            <div class="w-full">
              <div class="form-group">
                <label
                  class="form-label bg-w-white-2 text-w-blue-2"
                  :class="{
                    'bg-w-cyber-red-1 text-w-cyber-red-2':
                      v$.form.customer_group_id.$error,
                  }"
                >
                  Customer Group
                </label>
                <div
                  class="form-control h-9.5 absolute fc"
                  :class="
                    v$.form.customer_group_id.$error
                      ? 'border-w-cyber-red-1 border before:border-b-2 before:border-w-cyber-red-1'
                      : 'border-w-white-2 border before:border-b-2 before:border-w-white-2'
                  "
                ></div>
                <treeselect
                  v-model="form.customer_group_id"
                  :multiple="false"
                  :appendToBody="true"
                  placeholder="Select Customer Group"
                  :options="listGroups"
                  class="relative z-5 text-white text-sm placeholder-w-white-2 mt-0.5"
                  :class="{
                    'text-w-cyber-red-1': v$.form.customer_group_id.$error,
                  }"
                />
              </div>
              <div
                v-if="v$.form.customer_group_id.$error"
                class="text-red-500 font-semibold text-xs capitalize"
              >
                {{
                  `Area Support ${v$.form.customer_group_id.$errors[0].$message}`
                }}
              </div>
            </div>

            <div class="w-full">
              <div class="form-group">
                <label
                  class="form-label bg-w-white-2 text-w-blue-2"
                  :class="{
                    'bg-w-cyber-red-1 text-w-cyber-red-2':
                      v$.form.customer_type_id.$error,
                  }"
                >
                  Customer Type
                </label>
                <div
                  class="form-control h-9.5 absolute fc"
                  :class="
                    v$.form.customer_type_id.$error
                      ? 'border-w-cyber-red-1 border before:border-b-2 before:border-w-cyber-red-1'
                      : 'border-w-white-2 border before:border-b-2 before:border-w-white-2'
                  "
                ></div>
                <treeselect
                  v-model="form.customer_type_id"
                  :multiple="false"
                  :options="listTypes"
                  :appendToBody="true"
                  placeholder="Select Customer Type"
                  class="relative z-4 text-white text-sm placeholder-w-white-2 mt-0.5"
                  :class="{
                    'text-w-cyber-red-1': v$.form.customer_type_id.$error,
                  }"
                />
              </div>
              <div
                v-if="v$.form.customer_type_id.$error"
                class="text-red-500 font-semibold text-xs capitalize"
              >
                {{
                  `Area Support ${v$.form.customer_type_id.$errors[0].$message}`
                }}
              </div>
            </div>
          </div>
        </div>
      </line-thin>
      <line-thin class="sm:col-span-2">
        <div class="gap-4 p-4 grid sm:grid-cols-7 relative z-1">
          <customer-map
            class="rounded invert sm:col-span-3"
            v-model="location"
            :customer.sync="form"
          />
          <div class="space-y-1 sm:col-span-4">
            <div class="w-full">
              <div class="form-group">
                <label
                  class="form-label bg-w-white-2 text-w-blue-2"
                  :class="{
                    'bg-w-cyber-red-1 text-w-cyber-red-2':
                      v$.location.address.$error,
                  }"
                >
                  Address
                </label>
                <div
                  class="form-control"
                  :class="
                    v$.location.address.$error
                      ? 'border-w-cyber-red-1 border before:border-b-2 before:border-w-cyber-red-1'
                      : 'border-w-white-2 border before:border-b-2 before:border-w-white-2'
                  "
                >
                  <textarea
                    class="form-control px-3 py-2 text-white text-sm placeholder-w-white-2"
                    placeholder="Customer Address"
                    v-model="location.address"
                    rows="5"
                    :class="{
                      'text-w-cyber-red-1': v$.location.address.$error,
                    }"
                  />
                </div>
              </div>
            </div>

            <div
              v-if="v$.location.position.lat.$error"
              class="text-red-500 font-semibold text-xs capitalize"
            >
              Latitude and longitude is required. please select on map
            </div>
            <div
              v-if="v$.location.address.$error"
              class="text-red-500 font-semibold text-xs capitalize"
            >
              {{ `Address ${v$.location.address.$errors[0].$message}` }}
            </div>
          </div>
        </div>
      </line-thin>
    </div>

    <div class="flex justify-end pb-6 pt-1">
      <button
        class="cybr-btn group pr-3 pl-4 py-1 tracking-widest z-1"
        type="submit"
        @click="toSave"
        :disabled="conn"
      >
        <span class="font-Orbitron"> Create<span aria-hidden>_</span> </span>
        <span aria-hidden class="cybr-btn__glitch font-Orbitron">Create_</span>
        <span aria-hidden class="cybr-btn__tag font-Orbitron">R25</span>
      </button>
    </div>
  </div>
</template>

<script>
import LineThin from "@/components/LineThin";
import Treeselect from "@riophae/vue-treeselect";
import { mapActions, mapGetters, mapState } from "vuex";
import useVuelidate from "@vuelidate/core";
import { required, requiredIf } from "@vuelidate/validators";

import CustomerMap from "@/components/map/CustomerMap";

export default {
  name: "CustomerCreate",
  components: {
    Treeselect,
    LineThin,
    CustomerMap,
  },
  data() {
    return {
      form: {},
      conn: false,
      location: {
        address: "",
        position: {
          lat: -7.7831651,
          lng: 110.3696965,
        },
      },
      checker: false,
    };
  },
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    return {
      form: {
        number: {
          required,
        },
        name: {
          required,
        },
        phone_number: {
          required,
        },
        area_support_id: {
          required,
        },
        area_sales_id: {
          required: requiredIf(this.checker),
        },
        area_location_id: {
          required: requiredIf(this.checker),
        },
        customer_type_id: {
          required,
        },
        customer_group_id: {
          required,
        },
      },
      location: {
        address: {
          required,
        },
        position: {
          lat: { required },
          lng: { required },
        },
      },
    };
  },

  computed: {
    ...mapState({
      sales: (state) => state.customers.sales,
      locations: (state) => state.customers.locations,
    }),
    ...mapGetters("areas", ["listSupports"]),
    ...mapGetters("customers", ["listGroups", "listTypes"]),
  },
  mounted() {
    this.firstLoad();
  },
  methods: {
    ...mapActions("customers", ["CUSTOMER_CREATE"]),
    firstLoad() {
      this.form = Object.assign({}, this.$store.state.customers.form);
    },
    async toSave() {
      this.conn = true;
      const result = await this.v$.$validate();

      if (!result) {
        this.conn = false;
        return;
      }

      let form = this.form;
      form.address = this.location.address;
      form.latitude = this.location.position.lat;
      form.longitude = this.location.position.lng;

      let res = await this.CUSTOMER_CREATE(form);

      if (res) {
        this.$router.go(-1);
      }

      this.conn = false;
    },
  },
};
</script>
