import axios from "axios";
import TokenService from "./tokenService";
import store from "../store";

const instance = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

instance.interceptors.request.use(
  (config) => {
    const token = TokenService.getAccessToken();
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;

    if (err.response && err.response.data.status_code)
      console.log(err.response.data.message);
    else console.log("Terjadi kesalahan system");

    if (
      originalConfig.url !== "/api/signin" &&
      err.response.data.status_code == 401
    ) {
      store.dispatch("menu/RESPONSE_NOTIFICATIONS", err.response.data);
      store.dispatch("auth/LOGOUT");
    }

    return Promise.reject(err);
  }
);

export default instance;
